import styled, { css } from "styled-components";

import { FIELD_COLORS, FIELD_CSS } from "../../FormFieldsStyles/common";

//#region Select with Checkbox
const DropdownWithCheckboxOptions = css`
  .panel-content {
    border: 1px solid ${FIELD_COLORS.FIELD_BORDER_COLOR} !important;
  }
  .item-renderer {
    align-items: center;
  }
  .item-renderer input {
    margin-right: 6px;
  }
  .item-renderer span {
    ${({ theme }) => theme.text.inputRegular12pt};
  }
  .select-item:hover {
    background: ${FIELD_COLORS.MULTISELECT_OPTIONS_HOVER_BGCOLOR};
  }
  .select-item {
    margin-bottom: 3px;
  }
`;
const dropdownWithCheckBoxStyle = css`
  .dropdown-heading-value span {
    ${FIELD_CSS.TEXT};
  }
  .dropdown-heading-value span.gray {
    ${FIELD_CSS.PLACEHOLDER};
  }
  .dropdown-container {
    border: 1px solid ${FIELD_COLORS.FIELD_BORDER_COLOR};
  }
  .dropdown-container:hover {
    ${FIELD_CSS.HOVER};
  }
  .dropdown-container:active {
    ${FIELD_CSS.HOVER};
  }
  .dropdown-container:focus {
    ${FIELD_CSS.HOVER};
  }
  .dropdown-container:focus-within {
    ${FIELD_CSS.HOVER};
  }
  .dropdown-container:focus-within .gray {
    color: transparent !important;
  }
`;
const errorDropdownWithCheckBoxStyle = css`
  ${dropdownWithCheckBoxStyle};
  .dropdown-container {
    border: 1px solid ${FIELD_COLORS.ERROR_FIELD_BORDER_COLOR} !important;
  }
  .dropdown-container:hover {
    ${FIELD_CSS.ERROR_HOVER};
  }
  .dropdown-container:active {
    ${FIELD_CSS.ERROR_HOVER};
  }
  .dropdown-container:focus {
    ${FIELD_CSS.ERROR_HOVER};
  }
  .dropdown-container:focus-within {
    ${FIELD_CSS.ERROR_HOVER};
  }
`;
export const DropdownWithCheckboxContainer = styled.div<{
  disabled: boolean;
  Error: boolean;
}>`
  ${({ Error }) =>
    Error ? errorDropdownWithCheckBoxStyle : dropdownWithCheckBoxStyle};
  ${DropdownWithCheckboxOptions};
`;
//#endregion
