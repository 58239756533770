import { Card } from "react-bootstrap";
import styled from "styled-components";
import { Typography } from "../Basic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { theme } from "../Theme";
import ReactApexChart from "react-apexcharts";
import { RadialChartData } from "./utils";
import { Grid } from "antd";
import { useAppSelector } from "src/Redux";
import { IDashboard } from "./Dashboard";
import { ApexOptions } from "apexcharts";

const HiringProgress = () => {
  const screen = Grid.useBreakpoint();
  const { hiring_progress } = useAppSelector((state) => state.dashboard);
  return (
    <HiringProgressCard>
      <div className="d-flex justify-content-between">
        <Typography
          content="Hiring Progress"
          color="black300"
          styleName="hiringProgresstitle"
        />
        <Wrapper className="d-flex align-items-center gap-2 ">
          <Typography
            content="View Details"
            color="primary_diverging"
            styleName="hiringProgressSubTitle"
          />
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare}
            style={{
              color: theme.colors.primary_diverging,
              marginBottom: "3px",
            }}
          />
        </Wrapper>
      </div>
      <ChartGroupWrapper>
        {Array.from({ length: 3 }, (_, index) =>
          RadialChartData.slice(index * 2, index * 2 + 2)
        ).map((chartGroup) => (
          <Container>
            {chartGroup.map((radial: ApexOptions & { key: string }) => (
              <ChartWrapper className={radial.key}>
                <ReactApexChart
                  options={radial}
                  series={[
                    hiring_progress[
                      radial.key as keyof IDashboard.IHiringProgress
                    ].percentage,
                  ]}
                  type="radialBar"
                  height={screen.lg ? 235 : 215}
                />
              </ChartWrapper>
            ))}
          </Container>
        ))}
      </ChartGroupWrapper>
    </HiringProgressCard>
  );
};

export default HiringProgress;

const HiringProgressCard = styled(Card)`
  padding: 24px 26px 29px 24px;
  .dark-theme & > div > label {
    color: ${theme.colors.black10};
  }
`;

const ChartGroupWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 991px) {
    flex-direction: column;
  } ;
`;
const ChartWrapper = styled.div`
  max-width: calc(100% / 2);
  @media (max-width: 991px) {
    max-width: calc(100% / 2);
  }
`;
const Container = styled.div`
  display: flex;
  width: calc(100% / 3);
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  label {
    cursor: pointer;
  }
`;
