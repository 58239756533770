import { Fragment, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { HELPERS } from "src/Common/Helpers";
import { ROUTES } from "src/utils/ROUTES";

export const Protected = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isLoggedin = HELPERS.isUserLoggedIn();

  useEffect(() => {
    if (isLoggedin) return;
    navigate(`${ROUTES.SIGN_IN}?redirectURL=${location.pathname}`);
  });

  return isLoggedin ? children : <Fragment />;
};