import { ColumnInstance } from "react-table";
import { UserIcon, ViewIcon } from "src/assets/custom_Icons";

export const pageJumperOptions = [
  { value: 1, label: "1" },
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 15, label: "15" },
];

export const customColumnCell = (column: ColumnInstance) => {
  return ["UserPhoto", "Options"].includes(column.Header as string)
    ? ""
    : column.render("Header");
};
export const tableColumns: any = [
  { Header: "UserPhoto", accessor: "profile_photo" },
  { Header: "Job", accessor: "title" },
  { Header: "Department", accessor: "department" },
  { Header: "Reference#", accessor: "reference" },
  { Header: "Date Created", accessor: "date_created" },
  { Header: "Campaign Status", accessor: "campaign" },
  {
    Header: () => (
      <div>
        <UserIcon height={16} width={16} />
      </div>
    ),
    accessor: "applications",
  },
  { Header: () => <ViewIcon height={16} width={16} />, accessor: "unread" },
  {
    Header: "Options",
  },
];
export const tableMobileColumns: any = [
  { Header: "Job", accessor: "title" },
  { Header: "Date Created", accessor: "date_created" },
  {
    Header: "Options",
  },
];

export const DropDownOption = {
  drafts: 0,
  archived: 1,
  active: 2,
};
