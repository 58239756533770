import { CommonProps } from "./Common";
import { createSlice } from "@reduxjs/toolkit";

const initialState: CommonProps.State = {
  currency_dict: {} as CommonProps.currency_dict,
  skills: [] as CommonProps.skills,
  industries: [] as CommonProps.industries,
  industryMap: {},
  cities: [] as CommonProps.cities,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setCurrencyDict: (state, action: CommonProps.setCurrencyDict) => {
      state.currency_dict = action.payload || false;
    },
    setSkills: (state, action: CommonProps.setSkills) => {
      state.skills = action.payload || false;
    },
    setIndustries: (state, action: CommonProps.setIndustries) => {
      state.industries = action.payload || false;
      const map = {} as CommonProps.industryMap;
      state.industries.forEach(({ id, name }) => {
        map[name] = id;
      });
      state.industryMap = map;
    },
    setCities: (state, action: CommonProps.setCities) => {
      state.cities = action.payload || false;
    },
  },
});

export const commonReducer = commonSlice.reducer;
export const commonActions = commonSlice.actions;
