import { Input } from "./input";
import { Password, getPasswordError } from "./password";
import { Search, SearchField } from "./search";
import { TextArea } from "./textArea";
import { Spinners } from "./spinners";

import { Dropdown, DropdownField } from "./dropdown";
import { DropdownWithCheckbox } from "./dropdownWithCheckbox";

import { Checkbox, CheckboxGroup } from "./checkbox";
import { Switch, SwitchGroup } from "./switch";
import { RadioGroup } from "./radio";

import { CountrySelector } from "./countrySelector";
import { CitySearch } from "./citySearch";

import { DatePickerElement } from "./datePicker";
import { CurrencySelector } from "./currencySelector";
import { InputWithIncrementButton } from "./inputWithIncrementButton";
import { RichTextArea } from "./richTextArea";

export const FormFields = {
  Input,
  Search,
  SearchField,
  Password,
  TextArea,
  Spinners,

  Dropdown,
  DropdownField,
  DropdownWithCheckbox,

  RadioGroup,
  Switch,
  SwitchGroup,
  Checkbox,
  CheckboxGroup,

  CountrySelector,
  CitySearch,

  DatePickerElement,
  CurrencySelector,
  InputWithIncrementButton,
  RichTextArea,
};
export const formFieldsHelpers = { getPasswordError };
