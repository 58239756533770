export const XIcon = ({ fill }: { fill: string }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99297 11.25L6.01797 7.27503L2.04297 11.25L0.542969 9.75003L4.51797 5.77503L0.617969 1.87502L2.06797 0.400024L5.99297 4.32503L9.91797 0.400024L11.418 1.90002L7.49297 5.82503L11.443 9.80003L9.99297 11.25Z"
        fill={fill}
      />
    </svg>
  );
};
