const DashboardPageIcon = () => {
  return (
    <svg
      width="250"
      height="250"
      viewBox="0 0 250 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_19_32128"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="250"
        height="250"
      >
        <rect width="250" height="250" fill="#FFBD5A" />
      </mask>
      <g mask="url(#mask0_19_32128)">
        <path
          d="M16.235 170.643L-0.277096 154.131C-2.94211 151.466 -7.2474 151.451 -9.89322 154.096L-32.8388 177.042C-35.4846 179.688 -35.469 183.993 -32.804 186.658L-16.2919 203.17C-13.6269 205.835 -9.32165 205.851 -6.67583 203.205L16.2697 180.259C18.9155 177.613 18.9 173.308 16.235 170.643Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.716 207.996C9.81284 208.322 8.04096 206.951 7.8785 205.029L5.37489 175.455C5.37725 171.872 8.28394 168.965 11.8664 168.963C15.4488 168.961 18.3517 171.864 18.3494 175.446L14.4339 205.172C14.2454 206.607 13.143 207.752 11.716 207.996Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-37.6306 158.346C-37.956 156.443 -36.5856 154.671 -34.6633 154.508L-5.0891 152.005C-1.50664 152.007 1.40005 154.914 1.40241 158.496C1.40478 162.079 -1.49808 164.982 -5.08054 164.979L-34.8064 161.064C-36.241 160.875 -37.3868 159.773 -37.6306 158.346Z"
          fill="white"
        />
        <path
          d="M8.33223 161.327C32.1542 185.149 70.9356 184.99 94.9528 160.973C118.97 136.956 119.128 98.1744 95.3064 74.3524C71.4844 50.5304 32.703 50.6887 8.68579 74.7059C-15.3315 98.7232 -15.4897 137.505 8.33223 161.327Z"
          fill="white"
        />
        <path
          d="M11.1604 158.498C31.0772 178.415 62.8937 178.89 82.2247 159.559C101.556 140.228 101.081 108.411 81.164 88.4945C61.2473 68.5777 29.4307 68.1028 10.0998 87.4338C-9.23117 106.765 -8.75629 138.581 11.1604 158.498Z"
          fill="#E2E2E2"
        />
        <path
          d="M11.5146 157.437C29.2834 175.206 57.1427 176.156 73.74 159.559C90.3373 142.961 89.3875 115.102 71.6187 97.3333C53.8498 79.5644 25.9906 78.6147 9.39327 95.2119C-7.20401 111.809 -6.25426 139.668 11.5146 157.437Z"
          fill="#323251"
        />
        <path
          d="M45.3135 106.7C51.8388 108.197 57.8372 106.355 58.7114 102.587C59.5855 98.818 55.0044 94.5493 48.4791 93.0523C41.9538 91.5552 35.9554 93.3968 35.0813 97.1656C34.2071 100.934 38.7882 105.203 45.3135 106.7Z"
          fill="white"
        />
        <path
          d="M25.4762 99.1002C27.2614 99.6282 28.9537 99.2341 29.2561 98.22C29.5585 97.2059 28.3564 95.9558 26.5712 95.4278C24.7861 94.8998 23.0937 95.2939 22.7913 96.308C22.4889 97.3221 23.691 98.5722 25.4762 99.1002Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.577 108.12C81.9759 121.437 78.3085 135.294 68.4284 145.174C51.8776 161.725 24.1641 160.846 6.52833 143.21C3.35473 140.037 0.7273 136.535 -1.35757 132.833C0.261755 141.824 4.64645 150.57 11.7555 157.679C29.3912 175.314 57.1047 176.194 73.6555 159.643C87.2281 146.07 89.0759 124.993 79.577 108.12Z"
          fill="black"
        />
        <path
          d="M-10.406 88.848C-6.69602 92.558 3.43462 88.4424 12.2214 79.6556C21.0082 70.8688 25.1238 60.7382 21.4138 57.0282C17.7038 53.3182 7.57318 57.4338 -1.21361 66.2206C-10.0004 75.0074 -14.116 85.138 -10.406 88.848Z"
          fill="white"
        />
        <path
          d="M81.5178 180.772C85.2278 184.482 95.3584 180.366 104.145 171.58C112.932 162.793 117.048 152.662 113.338 148.952C109.628 145.242 99.497 149.358 90.7102 158.145C81.9234 166.931 77.8078 177.062 81.5178 180.772Z"
          fill="white"
        />
        <path
          d="M8.33223 161.326C32.5447 185.539 71.801 185.539 96.0135 161.326C120.226 137.114 120.226 97.8577 96.0135 73.6452C71.801 49.4327 32.5447 49.4327 8.33223 73.6452C-15.8803 97.8577 -15.8803 137.114 8.33223 161.326Z"
          stroke="white"
          strokeWidth="12"
          strokeMiterlimit="10"
        />
        <path
          d="M143.5 57L145.408 62.8734L151.584 62.8734L146.588 66.5033L148.496 72.3766L143.5 68.7467L138.504 72.3766L140.412 66.5033L135.416 62.8734L141.592 62.8734L143.5 57Z"
          fill="white"
        />
        <path
          d="M203 165L205.47 172.601H213.462L206.996 177.298L209.466 184.899L203 180.202L196.534 184.899L199.004 177.298L192.538 172.601H200.53L203 165Z"
          fill="white"
        />
        <path
          d="M203 87L204.123 90.4549H207.755L204.816 92.5902L205.939 96.0451L203 93.9098L200.061 96.0451L201.184 92.5902L198.245 90.4549H201.877L203 87Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default DashboardPageIcon;
