import { useState } from "react";
import { Field, FieldProps } from "formik";

import { FormFieldCommon } from "../../FormFieldsCommon";
import { FormFieldsProps } from "../../FormFields";
import { TextareaContainer } from "./textAreaStyles";

export const TextArea = (props: FormFieldsProps.Textarea) => {
  const {
    fieldName,
    idPrefix,
    label,
    isRequired,
    errorMessage,
    disabled = false,
    rows = 3,
    isReadOnly = false,
    placeholder = label || fieldName,
    needLabelPlaceholder = false,
    textLimit = 300,
    showTextCount = false,
    showLabel,
  } = props;
  const [isFocused, setFocused] = useState(false);

  return (
    <Field name={fieldName} key={fieldName}>
      {({ field, meta, form }: FieldProps) => {
        const { onBlur, value = "" } = field;
        const { touched } = meta;
        const { setFieldValue } = form;

        const hasError = !isFocused && isRequired && touched && !value;
        const errorLabel = errorMessage || `${label || fieldName} is required`;
        const showErrorLabel = hasError && errorLabel;

        return (
          <TextareaContainer disabled={disabled} Error={hasError}>
            {showLabel && label && (
              <FormFieldCommon.Label
                content={`${label}${isRequired ? "*" : ""}`}
              />
            )}
            {!label && needLabelPlaceholder && (
              <FormFieldCommon.LabelPlaceHolder />
            )}
            <textarea
              id={`${idPrefix}_${fieldName}`}
              name={fieldName}
              className="form-control mg-t-20"
              placeholder={placeholder}
              required
              rows={rows}
              readOnly={isReadOnly}
              disabled={disabled}
              onChange={(e) => {
                const newValue = e.target.value;
                if (newValue.length > textLimit) return;
                setFieldValue(fieldName, newValue.slice(0, 100));
              }}
              onFocus={() => setFocused(true)}
              onBlur={(e) => {
                onBlur(e);
                setFocused(false);
              }}
              value={value}
            />
            {showTextCount && (
              <FormFieldCommon.Label
                className="textarea-text-count"
                content={`${value.length}/${textLimit}`}
              />
            )}
            {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
          </TextareaContainer>
        );
      }}
    </Field>
  );
};
