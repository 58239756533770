import { IIconProps } from "./customIcons";

const PaperClip: React.FC<IIconProps.Iprops> = ({ width }) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M89.3333 46.0416L51.0417 84.3333C46.3507 89.0243 39.9883 91.6597 33.3542 91.6597C26.7201 91.6597 20.3577 89.0243 15.6667 84.3333C10.9757 79.6423 8.34027 73.2799 8.34027 66.6458C8.34027 60.0117 10.9757 53.6493 15.6667 48.9583L53.9583 10.6666C57.0857 7.53927 61.3273 5.78235 65.75 5.78235C70.1727 5.78235 74.4143 7.53927 77.5417 10.6666C80.669 13.794 82.4259 18.0356 82.4259 22.4583C82.4259 26.881 80.669 31.1226 77.5417 34.25L39.2083 72.5416C37.6447 74.1053 35.5239 74.9838 33.3125 74.9838C31.1011 74.9838 28.9803 74.1053 27.4167 72.5416C25.853 70.9779 24.9745 68.8572 24.9745 66.6458C24.9745 64.4344 25.853 62.3136 27.4167 60.75L62.7917 25.4166"
        stroke="#4A4A69"
        strokeWidth={width || "3"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PaperClip;
