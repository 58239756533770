import { Button, Dropdown, Modal } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../Theme/Colors";
import { theme } from "../Theme";

export const PageContainer = styled.div`
  margin: 8px auto 30px;
  background: ${colors.white_pure};
  box-shadow: 1px 1px 7px rgba(154, 154, 204, 0.1);
  border-radius: 5px;
  padding: 10px;
  @media (min-width: 992px) {
    padding: 27px 22px 55px 82px;
    margin: 40px auto 30px;
  }

  .dark-theme & {
    background: ${colors.black110};
  }
`;
export const MainPageContainer = styled.div`
  margin: 8px;
  @media (min-width: 1200px) {
    margin: 40px auto 0px;
    width: 1190px;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
  gap: 17px;
  @media (max-width: 991px) {
    display: none;
  }
`;
export const TableFilterHeader = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: 991px) {
    gap: 40px;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;
  }
`;
export const Table = styled.table`
  @media (max-width: 991px) {
    thead th:last-child {
      width: 20px;
    }
    thead th:nth-child(2) {
      width: 135px !important;
    }
  }
  .dark-theme & .table-header {
    background: ${colors.black120};
    th {
      color: ${colors.white_pure} !important;
      svg path,
      svg circle {
        stroke: ${colors.white_pure}!important;
        fill: #4a4a69 !important;
      }
    }
  }
`;
export const RowCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  .progress-bar {
    height: 6px;
    border-radius: 0px;
  }
`;
export const JobRowCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 18px;
  .progress-bar {
    height: 6px;
    border-radius: 0px;
  }
  cursor: pointer;
  label {
    cursor: pointer;
  }
`;
export const IconContainer = styled.div<{ src: string }>`
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-position: center center;
  width: 50px;
  height: 50px;
  background-color: ${theme.colors.primary_default};
  border: 2px solid ${theme.colors.primary150};
  border-radius: 30px;
`;
export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  .user-icon {
    color: ${colors.setting_grey};
  }
`;
export const DropDownContainer = styled(Dropdown.Toggle)`
  background: transparent !important;
  border: none;

  &::after {
    display: none;
  }
  &:hover {
    background: transparent !important;
    border: none;
  }
`;
export const DropDownMenuContainer = styled(Dropdown.Menu)`
  margin: 0px;
  .dropdown-item:hover {
    background: ${colors.grey_input_dropdown_hover} !important;
  }
  &::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    background: ${colors.white_pure};
    transform: rotate(45deg);
    top: 0;
    right: 10px;
    z-index: -1;
  }
`;
export const PaginationButton = styled(Button)`
  color: ${colors.black_pure};
  font-weight: 500;
  font-size: 18px;
  border: none;
  &:disabled {
    color: ${colors.black50};
    border: none;
    cursor: not-allowed;
    pointer-events: visible;
  }
  &:active {
    border: none;
  }
`;
export const ModalButtonContainer = styled.div`
  button {
    font-weight: 600;
  }
  .dark-theme .modal-footer &:first-child button.btn-light {
    background: ${theme.colors.light_default} !important;
    color: ${theme.colors.black90};
  }
`;
export const ModalHeader = styled(Modal.Header)`
  .dark-theme & .modal-title label {
    color: ${theme.colors.black50};
  }
`;
export const ModalContainer = styled.div`
  margin: 26px 0px;
  display: flex;
  justify-content: center;
  .dark-theme & label {
    color: ${theme.colors.black10};
  }
`;
