import instance from "src/axios/axios-user";

import { JobProps } from "src/Redux/slices";
import { API_PATHS } from "src/utils/API_PATHS";

const create = (values: JobProps.CreateNovaJobAPI) =>
  instance.post(API_PATHS.createJob, JSON.stringify(values));

const get = (id: string | number) => instance.get(`${API_PATHS.getJob}/${id}`);

export const JOBS = { create, get };
