import styled from "styled-components";

import { theme } from "../../Theme";
import { TypographyProps } from "./TypographyProps";

export const Typography = ({
  styleName,
  content,
  color = "black100",
  className,
}: TypographyProps.IProps) => {
  return (
    <TextElement
      className={`typography ${className}`}
      styleName={styleName}
      color={color}
    >
      {content}
    </TextElement>
  );
};

export const TypographyLink = ({
  styleName,
  content,
  color = "black100",
  className,
}: TypographyProps.IProps) => {
  return (
    <TextLinkElement
      className={`typography ${className}`}
      styleName={styleName}
      color={color}
    >
      {content}
    </TextLinkElement>
  );
};

const TextElement = styled.label<TypographyProps.ElementProps>`
  ${({ styleName }) => theme.text[styleName]};
  color: ${({ color }) => theme.colors[color]};
  margin-bottom: 0;
`;
const TextLinkElement = styled.a<TypographyProps.ElementProps>`
  ${({ styleName }) => theme.text[styleName]};
  color: ${({ color }) => theme.colors[color]} !important;
  cursor: pointer;
  text-decoration: underline !important;
  margin-bottom: 0;
`;
