import styled from "styled-components";

const CreateJobContainer = styled.div`
  border-radius: 5px;
  padding: 20px 12px 24px 12px;

  @media (min-width: 768px) {
    padding: 20px;
  }
`;
const CreateJobFormContainer = styled.div`
  .form-group {
    margin-bottom: 0px;
  }
  .left-side {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .right-side {
    margin-top: 32px;
    @media (min-width: 768px) {
      margin-top: 0;
    }
  }
`;
const CreateJobSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 28px;
  padding-left: 4px;

  @media (min-width: 768px) {
    padding-left: 0;
  }
`;
const CreateJobFormWrapper = styled.div`
  @media (min-width: 768px) {
    padding: 30px;
    padding-top: 0;
  }
`;
const PageContainer = styled.div`
  background: #f4f5f4;
  padding: 0 8px 20px 8px;

  @media screen and (min-width: 768px) {
    padding: 0 40px 60px 40px;
  }
  @media (min-width: 992px) {
    padding: 0 80px 94px 80px;
  }
  @media (min-width: 1200px) {
    padding: 0 125px 94px 125px;
  }
`;
const PageHeading = styled.div`
  padding: 20px 0;
  @media (min-width: 992px) {
    padding: 40px 0;
  }
`;
const QualifierContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
  align-items: center;
`;
const NextButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 52px;
  padding-right: 4px;
  @media (min-width: 768px) {
    padding-right: 10px;
  }
`;

export const CreateJobStyles = {
  CreateJobContainer,
  CreateJobFormContainer,
  CreateJobSubtitle,
  CreateJobFormWrapper,
  PageContainer,
  PageHeading,
  QualifierContainer,
  NextButtonWrapper,
};

const QualificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
`;
const FormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;
  background: ${({ theme }) => theme.colors.light_default};
  box-shadow: 1px 1px 7px rgba(154, 154, 204, 0.1);
  border-radius: 5px;
  padding: 24px 20px;
`;
const DeleteIconContainer = styled.div`
  width: fit-content;
  position: absolute;
  right: 20px;
  cursor: pointer;
`;
export const QualificationStyles = {
  QualificationContainer,
  FormContainer,
  DeleteIconContainer,
};
