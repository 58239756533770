import { createSlice } from "@reduxjs/toolkit";
import { IJobs } from "src/components/Job/Job";
const initialState: { jobs: IJobs.Job[] } = {
  jobs: [],
};

const activeJobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    getJobs: (state, action) => {
      state.jobs = action.payload;
    },
  },
});

export const activeJobActions = activeJobSlice.actions;
export const activeJobReducer = activeJobSlice.reducer;
