import { Formik } from "formik";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Grid } from "antd";

import { Typography } from "src/components/Basic";
import Input from "../Components/Input";
import { forgotValidationSchema } from "../Components/Utils";
import { ButtonWrapper } from "../Components/style";
import { ROUTES } from "src/utils/ROUTES";
import { theme } from "src/components/Theme";

export const Forgot = () => {
  const screen = Grid.useBreakpoint();

  return (
    <Container>
      <div className="d-flex justify-content-center">
        <Typography
          content="Forgot Password"
          styleName={screen.lg ? "authTitle" : "authTitleMobile"}
          color={screen.lg ? "black_pure" : "primary_default"}
        />
      </div>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={(values) => console.log("SUBMITTED VALUES", values)}
        validationSchema={forgotValidationSchema}
      >
        {(formik) => {
          return (
            <InputFieldWrapper>
              <Input
                fieldName="email"
                placeholder="Enter your email"
                isRequired={true}
                errorMessage={formik.errors.email}
              />

              <ButtonWrapper
                disabled={!formik.isValid}
                type="submit"
                onClick={() => {
                  formik.submitForm();
                }}
              >
                <Typography
                  content="Reset"
                  styleName="tableButton"
                  color="white_pure"
                />
              </ButtonWrapper>
            </InputFieldWrapper>
          );
        }}
      </Formik>
      <ButtonContainer>
        <NavLink to={ROUTES.SIGN_IN}>
          <Typography
            content="Sign In"
            styleName="forgotButtons"
            color="black60"
          />
        </NavLink>
        <NavLink to={ROUTES.REGISTER}>
          <Typography
            content="Register"
            styleName="forgotButtons"
            color="black60"
          />
        </NavLink>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 100px;
  @media (min-width: 992px) {
    padding: 0;
  }
`;
const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 328px;
  margin: 36px auto 48px;
  @media (min-width: 992px) {
    margin: 108px auto 60px;
    min-width: 348px;
    gap: 60px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 35px;

  @media (max-width: 991px) {
    gap: 8px;
    a {
      background: ${theme.colors.light_default};
      padding: 9px 36px;
      border: 1px solid ${theme.colors.light_outline};
      border-radius: 5px;
    }
    a:active {
      background: ${theme.colors.light_outline};
    }
  }

  label:hover {
    color: ${theme.colors.black70};
    cursor: pointer;
  }
`;
