import qs from "qs";

import { LoginProps, RegisterProps, OTPProps } from "src/Redux/slices";
import instance from "src/axios/axios-user";
import { API_PATHS } from "src/utils/API_PATHS";

const register = (body: RegisterProps.FormValues) => {
  const config = {
    method: "post",
    url: API_PATHS.register,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(body),
  };
  return instance(config);
};

const checkUserVerifiedAfterLogin = () => {
  return instance.get(API_PATHS.checkUserVerifiedAfterLogin);
};
const otpVerificationAfterLogin = (token: string) => {
  const qs = require("qs");
  let data = qs.stringify({ token });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: API_PATHS.otpVerificationAfterLogin,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data,
  };
  return instance(config);
};

const login = (body: LoginProps.FormValues) => {
  var data = qs.stringify(body);
  var config = {
    method: "post",
    url: API_PATHS.login,
    headers: {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };
  return instance(config);
};

const otpVerificationAfterRegister = (body: OTPProps.OTPAfterRegister) => {
  const config = {
    method: "post",
    url: API_PATHS.otpVerificationAfterRegister,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(body),
  };
  return instance(config);
};

const logout = () => instance.post(API_PATHS.logout);

export const AUTH = {
  register,
  login,
  otpVerificationAfterRegister,
  checkUserVerifiedAfterLogin,
  otpVerificationAfterLogin,
  logout,
};
