import { Row, Col } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useContext, useState } from "react";

import { FormFields } from "../../Basic/FormFields";
import { XIcon } from "src/Icons/xIcon";
import { IconButton } from "src/components/Basic";
import { AppContext } from "src/App";
import { CreateJobContext } from ".";
import { JobProps } from "src/Redux/slices";
import { CreateJobProps } from "./CreateJob";
import { QualificationStyles } from "./Styles";

const responseTypeOptions = [
  { label: "Yes/No", value: "yesno" },
  { label: "Numeric", value: "numeric" },
];
const idealAnswerOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

export const Question = ({ questionNumber }: CreateJobProps.Question) => {
  const { removeQuestion } = useContext(CreateJobContext);

  const { isDarkTheme } = useContext(AppContext);
  const xIconFillColor = isDarkTheme ? "#A9ABBD" : "black";

  const [answerType, setAnswerType] = useState<JobProps.response_type>("yesno");

  return (
    <QualificationStyles.FormContainer
      key={questionNumber}
      className="question-container"
    >
      <QualificationStyles.DeleteIconContainer
        onClick={() => removeQuestion(questionNumber)}
      >
        <XIcon fill={xIconFillColor} />
      </QualificationStyles.DeleteIconContainer>
      <Row>
        <Col md={6}>
          <FormFields.TextArea
            fieldName={"qualifying_question" + questionNumber}
            idPrefix="qualifying-question"
            isRequired={false}
            label="Qualifying Question"
            placeholder="Ask a question..."
            textLimit={100}
            showTextCount={true}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <FormFields.Dropdown
            fieldName={"response_type" + questionNumber}
            idPrefix="qualifying-question"
            isRequired={false}
            label="Response Type"
            options={responseTypeOptions}
            onChangeHandler={(value: JobProps.response_type) =>
              setAnswerType(value)
            }
            defaultValue={responseTypeOptions[0]}
            allowSearch={false}
          />
        </Col>
        <Col md={3}>
          {answerType === "yesno" && (
            <FormFields.Dropdown
              key={`ideal_answer${questionNumber}_yesno`}
              fieldName={"ideal_answer" + questionNumber}
              idPrefix="qualifying-question"
              isRequired={false}
              label="Ideal Answer"
              options={idealAnswerOptions}
              placeholder=" "
              defaultValue={idealAnswerOptions[0]}
              allowSearch={false}
            />
          )}
          {answerType === "numeric" && (
            <FormFields.Input
              key={`ideal_answer${questionNumber}_numeric`}
              fieldName={"ideal_answer" + questionNumber}
              idPrefix="qualifying-question"
              isRequired={false}
              label="Ideal Answer (Minimum)"
              placeholder=" "
              inputType="number"
            />
          )}
        </Col>
        <Col>
          <FormFields.Checkbox
            fieldName={"reject_criteria" + questionNumber}
            idPrefix="qualifying-question"
            label="Reject applicant if criteria is not met"
            isRequired={false}
            needLabelPlaceholder={true}
          />
        </Col>
      </Row>
    </QualificationStyles.FormContainer>
  );
};

export const QualifyingQuestion = ({
  questionComponents,
  addQuestion,
}: CreateJobProps.qualifyingQuestion) => {
  const MAX_QUESTION_COUNT = 5;
  const canAddQuestion =
    Object.keys(questionComponents).length < MAX_QUESTION_COUNT;

  return (
    <QualificationStyles.QualificationContainer>
      {Object.values(questionComponents).map((question) => question)}
      <Row style={{ width: "fit-content", margin: "auto" }}>
        <IconButton
          handleClick={addQuestion}
          icon={faPlus}
          position="right"
          label="Add another"
          isDisable={!canAddQuestion}
        />
      </Row>
    </QualificationStyles.QualificationContainer>
  );
};
