import { TextProps } from "./TextProps";

const MAIN_FONT = "Poppins";
const SECONDARY_FONT = "Inter";
const PRIMARY_FONT = "Roboto";

const display: TextProps.display = {
  display1: {
    fontFamily: MAIN_FONT,
    fontSize: "92px",
    fontWeight: 300,
    lineHeight: "115px",
    letterSpacing: "1.5px",
  },
  display2: {
    fontFamily: MAIN_FONT,
    fontSize: "86px",
    fontWeight: 300,
    lineHeight: "106px",
    letterSpacing: "0em",
  },
  display3: {
    fontFamily: MAIN_FONT,
    fontSize: "70px",
    fontWeight: 300,
    lineHeight: "86px",
    letterSpacing: "0em",
  },
  display4: {
    fontFamily: MAIN_FONT,
    fontSize: "54px",
    fontWeight: 400,
    lineHeight: "67px",
    letterSpacing: "0em",
  },
  display5: {
    fontFamily: MAIN_FONT,
    fontSize: "48px",
    fontWeight: 400,
    lineHeight: "58px",
    letterSpacing: "0em",
  },
  display6: {
    fontFamily: MAIN_FONT,
    fontSize: "40px",
    fontWeight: 400,
    lineHeight: "48px",
    letterSpacing: "0em",
  },
};

const heading: TextProps.heading = {
  heading1: {
    fontFamily: MAIN_FONT,
    fontSize: "36px",
    fontWeight: 600,
    lineHeight: "42px",
    letterSpacing: "0px",
  },
  heading2: {
    fontFamily: MAIN_FONT,
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "34px",
    letterSpacing: "0px",
  },
  heading3: {
    fontFamily: MAIN_FONT,
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "29px",
    letterSpacing: "0px",
  },
  heading4: {
    fontFamily: MAIN_FONT,
    fontSize: "21px",
    fontWeight: 500,
    lineHeight: "25px",
    letterSpacing: "0em",
  },
  heading5: {
    fontFamily: MAIN_FONT,
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "36px",
    letterSpacing: "0px",
  },
  heading6: {
    fontFamily: MAIN_FONT,
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "21px",
    letterSpacing: "0em",
  },
};

const subtitle: TextProps.subtitle = {
  subtitle1: {
    fontFamily: MAIN_FONT,
    fontSize: "17px",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "0px",
  },
  subtitle2: {
    fontFamily: MAIN_FONT,
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0px",
  },
  subtitle3: {
    fontFamily: MAIN_FONT,
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "17px",
    letterSpacing: "0px",
  },
};

const textDefault: TextProps.textDefault = {
  text1: {
    fontFamily: MAIN_FONT,
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.01em",
  },
  tableButton: {
    fontFamily: MAIN_FONT,
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "21px",
  },
  text_10pt: {
    fontFamily: MAIN_FONT,
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  inboxText: {
    md: {
      fontFamily: MAIN_FONT,
      fontSize: "11px",
      fontWeight: 700,
      lineHeight: "7px",
    },
    lg: {
      fontFamily: MAIN_FONT,
      fontSize: "12px",
      fontWeight: 800,
      lineHeight: "7px",
    },
  },
  countText: {
    fontFamily: MAIN_FONT,
    fontSize: "11px",
    fontWeight: 800,
    lineHeight: "17px",
  },
  candidateName: {
    fontFamily: MAIN_FONT,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "17px",
  },
  candidateNameEmailOpen: {
    fontFamily: MAIN_FONT,
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "17px",
  },
  pipelineDefaultNav: {
    fontFamily: MAIN_FONT,
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "14px",
    letterSpacing: "2%",
  },
  pipelineActiveNav: {
    fontFamily: MAIN_FONT,
    fontSize: "12px",
    fontWeight: 900,
    lineHeight: "14px",
    letterSpacing: "2%",
  },
  pipelineNavCount: {
    fontFamily: MAIN_FONT,
    fontSize: "7px",
    fontWeight: 800,
    lineHeight: "7px",
  },
  pipelineText: {
    sm: {
      fontFamily: MAIN_FONT,
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "36px",
    },
    lg: {
      fontFamily: MAIN_FONT,
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "36px",
    },
  },
  careerYear: {
    fontFamily: MAIN_FONT,
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "21px",
  },
  activityTextHeading: {
    fontFamily: MAIN_FONT,
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: "18px",
  },
  activityTextDesc: {
    fontFamily: MAIN_FONT,
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
  },
  summaryTextDesc: {
    fontFamily: MAIN_FONT,
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "20px",
  },
  activityDateText: {
    fontFamily: MAIN_FONT,
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "12px",
  },
  summaryDateText: {
    fontFamily: MAIN_FONT,
    fontSize: "11px",
    fontWeight: 600,
    lineHeight: "20px",
  },
  backButton: {
    fontFamily: MAIN_FONT,
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
  },
  qualifiersIdel: {
    fontFamily: MAIN_FONT,
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "20px",
  },
  qualifiersResponse: {
    fontFamily: MAIN_FONT,
    fontSize: "13px",
    fontWeight: 600,
    lineHeight: "20px",
  },
  qualifiersResponseMobile: {
    fontFamily: MAIN_FONT,
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "20px",
  },

  authInputText: {
    fontFamily: MAIN_FONT,
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
  },
  authInputErrorMsg: {
    fontFamily: MAIN_FONT,
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
  },
  authTitleMobile: {
    fontFamily: MAIN_FONT,
    fontSize: "21px",
    fontWeight: 600,
    lineHeight: "34px",
    letterSpacing: "0.5px",
  },
  authTitle: {
    fontFamily: MAIN_FONT,
    fontSize: "25px",
    fontWeight: 600,
    lineHeight: "34px",
    letterSpacing: "0.5px",
  },
  otpTitle: {
    fontFFamily: MAIN_FONT,
    fontSize: "25px",
    fontWeight: 600,
    lineHeight: "34px",
    letterSpacing: "0.5px",
    textAlign: "center",
  },
  otpTitleMobile: {
    fontFamily: MAIN_FONT,
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "28px",
    letterSpacing: "0.5px",
    textAlign: "center",
  },
  otpSubTitle: {
    fontFamily: MAIN_FONT,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0.5px",
    textAlign: "center",
  },
  otpSubTitleMobile: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "0.5px",
  },
  otpText: {
    fontFamily: MAIN_FONT,
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.4000000059604645px",
  },
  otpTextMobile: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "0.4px",
  },
  otpTextLink: {
    fontFamily: MAIN_FONT,
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.4000000059604645px",
  },
  forgotPassword: {
    fontFamily: MAIN_FONT,
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "16px",
  },
  authDescription: {
    fontFamily: MAIN_FONT,
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  createAccount: {
    fontFamily: MAIN_FONT,
    fontSize: "13px",
    fontWeight: 300,
    lineHeight: "20px",
    letterSpacing: "0.3px",
  },
  authDropDownText: {
    fontFamily: SECONDARY_FONT,
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "20px",
  },
  authDropDownTextHover: {
    fontFamily: SECONDARY_FONT,
    fontSize: "13px",
    fontWeight: 700,
    lineHeight: "20px",
  },
  authText: {
    fontFamily: MAIN_FONT,
    fontSize: "40px",
    fontWeight: 500,
    lineHeight: "46px",
  },
  tagText: {
    fontFamily: MAIN_FONT,
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "13px",
  },
  candidateText: {
    fontFamily: MAIN_FONT,
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "20px",
  },
  fileNameText: {
    fontFamily: MAIN_FONT,
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "17px",
  },
  emailConfirmedText: {
    fontFamily: MAIN_FONT,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0.5px",
    textAlign: "center",
  },
  forgotButtons: {
    fontFamily: MAIN_FONT,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  dashboardLabel: {
    fontFamily: MAIN_FONT,
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "36px",
  },
  activejobdec: {
    fontFamily: MAIN_FONT,
    fontSize: "17px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  activejobdecMobile: {
    fontFamily: MAIN_FONT,
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: "22px",
  },
  chartTitle: {
    fontFamily: MAIN_FONT,
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "17px",
  },
  hiringProgresstitle: {
    fontFamily: MAIN_FONT,
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  hiringProgressSubTitle: {
    fontFamily: PRIMARY_FONT,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  },
};
const table: TextProps.Table = {
  tableHeading: {
    fontFamily: MAIN_FONT,
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
  },
  tableSubHeading: {
    fontFamily: MAIN_FONT,
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
  },
  tableRowDefault: {
    fontFamily: MAIN_FONT,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
  },
};

const pagination: TextProps.pagination = {
  inputLabel: {
    fontFamily: MAIN_FONT,
    fontSize: "13px",
    fontWeight: 600,
    lineHeight: "21px",
    letterSpacing: "0em",
  },
  inputGeneral: {
    fontFamily: MAIN_FONT,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0em",
  },
  inputRegular12pt: {
    fontFamily: MAIN_FONT,
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.01em",
  },
  questionairre: {
    fontFamily: MAIN_FONT,
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: "17px",
    letterSpacing: "0em",
  },
  text_11pt_02: {
    fontFamily: MAIN_FONT,
    fontSize: "11px",
    fontWeight: 300,
    lineHeight: "16px",
    letterSpacing: "0.01em",
  },
  text_11pt: {
    fontFamily: MAIN_FONT,
    fontSize: "11px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0em",
  },
  date_picker_title: {
    fontFamily: MAIN_FONT,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "23px",
    letterSpacing: "0em",
  },
  date_picker_day: {
    fontFamily: MAIN_FONT,
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "27px",
    letterSpacing: "0em",
  },
  text_13pt: {
    fontFamily: MAIN_FONT,
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.01em",
    textAlign: "left",
  },
  text_12pt: {
    fontFamily: MAIN_FONT,
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.01em",
  },
};

const text = {
  ...display,
  ...heading,
  ...subtitle,
  ...textDefault,
  ...pagination,
  ...table,
};
const textObjects = {
  display,
  heading,
  subtitle,
  textDefault,
  pagination,
  text,
  table,
};

export { text, textObjects };
