const basepath = process.env.REACT_APP_NOVA_API;

const login = basepath + "/login";
const logout = basepath + "/logout";
const register = basepath + "/nova-register";

const checkUserVerifiedAfterLogin = basepath + "/nova-register/is-verified";
const otpVerificationAfterRegister = basepath + "/nova-register/verify";
const otpVerificationAfterLogin = basepath + "/nova-register/authed-verify";
const jobOverview = basepath + "/jobs/overview";

const company = basepath + "/company";
const pipelineApplicants = basepath + "/pipeline-applicants";
const pipelineOverview = basepath + "/pipeline";
const candidateOverview = basepath + "/candidate-overview";
const candidateExperience = basepath + "/candidate-experience";
const candidateFiles = basepath + "/candidate-files";
const candidateQualifiers = basepath + "/candidate-qualifiers";
const candidateComments = basepath + "/pipeline-comments";
const jobDashboard = basepath + "/job-dashboard";

const getJob = basepath + "/jobs";
const createJob = basepath + "/jobs";

const getCurrency = basepath + "/currency";
const getSkills = basepath + "/skills/";
const getIndustries = basepath + "/industries/";
const getCities = basepath + "/cities";
const getCityById = basepath + "/city";



export const API_PATHS = {
  login,
  logout,
  register,
  otpVerificationAfterRegister,
  otpVerificationAfterLogin,
  checkUserVerifiedAfterLogin,
  jobOverview,
  company,
  pipelineApplicants,
  pipelineOverview,
  candidateOverview,
  candidateExperience,
  candidateFiles,
  candidateQualifiers,
  candidateComments,
  jobDashboard,
  getJob,
  createJob,
  getCurrency,
  getSkills,
  getIndustries,
  getCities,
  getCityById,
};
