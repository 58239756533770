export const InfoIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99935 18.8334C14.6017 18.8334 18.3327 15.1025 18.3327 10.5001C18.3327 5.89771 14.6017 2.16675 9.99935 2.16675C5.39698 2.16675 1.66602 5.89771 1.66602 10.5001C1.66602 15.1025 5.39698 18.8334 9.99935 18.8334Z"
      stroke="#4EC2F0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 13.8333V10.5"
      stroke="#4EC2F0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 7.16675H10.0083"
      stroke="#4EC2F0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
