import React from "react";
import styled from "styled-components";
import { TableButton } from "../TableButton";
import { TableFilterProps } from "./TableFilter";

export const TableFilter: React.FC<TableFilterProps.IProps> = ({
  filterType,
  setFilterType,
}) => {
  return (
    <ButtonContainer>
      <TableButton
        label="Active Jobs"
        isActive={filterType === "active"}
        handleClick={() => setFilterType("active")}
      ></TableButton>
      <TableButton
        label="Drafts"
        isActive={filterType === "drafts"}
        handleClick={() => setFilterType("drafts")}
      ></TableButton>
      <TableButton
        label="Archived"
        isActive={filterType === "archived"}
        handleClick={() => setFilterType("archived")}
      ></TableButton>
    </ButtonContainer>
  );
};

export const ButtonContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
  gap: 17px;
`;
