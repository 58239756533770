import { Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import { Grid } from "antd";
import {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import "./createJob.css";

import {
  FormFields,
  FormFieldCommon,
  formikSubmitHandler,
  FormFieldsProps,
} from "../../Basic/FormFields";
import { Buttons, Typography } from "src/components/Basic";
import { QualifyingQuestion, Question } from "./QualifyingQuestion";
import {
  CreateJobFields,
  idPrefix,
  createJobValidationSchema,
} from "./CreateJobUtils";
import { JobProps } from "src/Redux/slices/Job/Job";
import { API_CALLS } from "src/API_CALLS";
import {
  CommonProps,
  useAppDispatch,
  useAppSelector,
  actions,
} from "src/Redux";
import { CreateJobProps } from "./CreateJob";
import { CreateJobStyles } from "./Styles";
import { useNavigate } from "react-router-dom";
import { AppContext } from "src/App";

export const CreateJobContext = createContext({} as CreateJobProps.Context);
export const CreateJob = () => {
  const { isDarkTheme } = useContext(AppContext);
  const screen = Grid.useBreakpoint();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { common, job } = useAppSelector((store) => store);
  const { currency_dict, industries, skills } = common;
  const { isAPICallInProgress } = job;

  //#region useStates
  const [lastQuestionNumber, setLastQuestionNumber] = useState(0);
  const [questionComponents, setQuestionComponents] = useState(
    {} as { [key: string]: JSX.Element }
  );
  const [submitClicked, setSubmitClicked] = useState(false);
  const [isAPICallMade, setAPIAPICallMade] = useState(false);
  //#endregion

  //#region useEffects
  useEffect(() => {
    if (isAPICallMade) return;
    if (currency_dict && Object.keys(currency_dict).length > 0) return;

    API_CALLS.COMMON.getCurrencyDict();
    setAPIAPICallMade(true);
  }, [currency_dict, isAPICallMade]);

  useEffect(() => {
    if (isAPICallMade) return;
    if (skills && Object.keys(skills).length > 0) return;

    API_CALLS.COMMON.getSkills();
    setAPIAPICallMade(true);
  }, [skills, isAPICallMade]);

  useEffect(() => {
    if (isAPICallMade) return;
    if (industries && Object.keys(industries).length > 0) return;

    API_CALLS.COMMON.getIndustries();
    setAPIAPICallMade(true);
  }, [industries, isAPICallMade]);
  //#endregion

  //#region Helper functions
  const removeQuestion = (questionNumber: number) => {
    const newQuestionComponents = { ...questionComponents };
    delete newQuestionComponents[questionNumber];
    setQuestionComponents(newQuestionComponents);
  };
  const addQuestion = () => {
    const newQuestionNumber = lastQuestionNumber + 1;
    const newQuestionComponents = { ...questionComponents };
    const newQuestion = <Question questionNumber={newQuestionNumber} />;
    newQuestionComponents[newQuestionNumber] = newQuestion;
    setLastQuestionNumber(newQuestionNumber);
    setQuestionComponents(newQuestionComponents);
  };
  //#endregion

  const questionComponent = (
    <QualifyingQuestion {...{ questionComponents, addQuestion }} />
  );

  const initialValues = {
    show_company_gallery: false,
    add_external_apply_URL: false,
    add_qualifying_question: false,
  } as JobProps.CreateNovaJobForm;

  //#region Autocomplete fields
  const industryOptions: CommonProps.industryOptions = Object.values(
    industries
  ).map(({ id, name }) => ({ label: name, value: id }));
  const skillOptions: CommonProps.skillOptions = Object.values(skills).map(
    ({ id, name }) => ({ label: name, value: id })
  );
  const onIndustryChangeHandler = (value: string) => {
    industryOptions.push({ label: value, value });
  };
  const onSkillsChangeHandler = (value: string) => {
    skillOptions.push({ label: value, value });
  };
  //#endregion

  return (
    <CreateJobContext.Provider value={{ removeQuestion }}>
      <CreateJobStyles.PageContainer className="page-container">
        <CreateJobStyles.PageHeading className="page-heading">
          <Typography
            styleName="heading5"
            content="CREATE JOB"
            color={isDarkTheme ? "black10" : "black90"}
          />
        </CreateJobStyles.PageHeading>
        <CreateJobStyles.CreateJobContainer className="create-job-container">
          <CreateJobStyles.CreateJobSubtitle>
            <Typography
              styleName="subtitle3"
              content="Let’s get started!"
              color={isDarkTheme ? "grey_subtitle1" : "blue_grey_80"}
            />
            <Typography
              styleName="text_13pt"
              content="Please complete the following."
              color={isDarkTheme ? "grey_subtitle2" : "black70"}
            />
          </CreateJobStyles.CreateJobSubtitle>

          <CreateJobStyles.CreateJobFormWrapper>
            <Formik
              initialValues={initialValues}
              onSubmit={() => {}}
              validationSchema={createJobValidationSchema}
            >
              {(formik) => {
                const onSubmitHandler = () => {
                  dispatch(actions.job.setAPICallInProgress(true));
                  const questions = Object.keys(questionComponents);
                  const _ = formik.values;

                  const qualifying_questions = questions.map((question) => ({
                    ideal_answer: _[`ideal_answer${question}`],
                    qualifying_question: _[`qualifying_question${question}`],
                    reject_criteria: _[`reject_criteria${question}`],
                    response_type: _[`response_type${question}`],
                  }));

                  //#region Allow multiple fields
                  const industries = [] as JobProps.industries[];
                  const industries_custom = [] as JobProps.industries_custom[];
                  _.industry.forEach((industry) => {
                    if (isNaN(industry.value))
                      industries_custom.push({ name: industry.value });
                    else industries.push({ id: +industry.value });
                  });

                  const skills = [] as JobProps.skill[];
                  const skills_custom = [] as JobProps.skills_custom[];
                  _.skills.forEach((skill) => {
                    if (isNaN(skill.value))
                      industries_custom.push({ name: skill.value });
                    else industries.push({ id: +skill.value });
                  });
                  //#endregion

                  const base_currency =
                    _.base_currency as FormFieldsProps.option;

                  const createJobAPIData: JobProps.CreateNovaJobAPI = {
                    advertised_type: "0", //_.advertised_type,
                    city: _.city.value,
                    country: _.country.name,
                    department: _.department,
                    experience_from: _.experience_from,
                    experience_to: _.experience_to,
                    external_apply: _.external_apply,
                    industry: [{ industries, industries_custom }],
                    job_description: _.job_description,
                    job_title: _.job_title,
                    qualifying_questions,
                    remuneration_from: _.remuneration_from,
                    remuneration_to: _.remuneration_to,
                    show_company_gallery: _.show_company_gallery,
                    skills: [{ skills, skills_custom }],
                    type: _.type,
                    work_environment: _.work_environment,
                    base_currency: base_currency.label,
                  };
                  API_CALLS.JOBS.create(createJobAPIData, navigate);
                };
                const _ = formik.values;
                if (
                  Object.keys(questionComponents).length === 0 &&
                  _.add_qualifying_question
                ) {
                  addQuestion();
                }

                const Additional = (
                  <>
                    <FormFields.Checkbox
                      {...CreateJobFields.show_company_gallery}
                    />
                    <div>
                      <FormFields.Checkbox
                        {...CreateJobFields.add_external_apply_URL}
                      />
                      {_.add_external_apply_URL && (
                        <FormFields.Input {...CreateJobFields.external_apply} />
                      )}
                    </div>
                    <CreateJobStyles.QualifierContainer>
                      <FormFields.Switch
                        {...CreateJobFields.add_qualifying_question}
                      />
                      <FormFieldCommon.AdditionalLabel
                        content="(max 5 questions)"
                        className="component-additional-label"
                      />
                    </CreateJobStyles.QualifierContainer>
                  </>
                );

                return (
                  <Fragment>
                    <CreateJobStyles.CreateJobFormContainer>
                      <Row>
                        <Col md={6} lg={5} xxl={4} className="left-side">
                          <FormFields.Input {...CreateJobFields.job_title} />
                          <FormFields.CountrySelector
                            {...CreateJobFields.country}
                          />
                          <FormFields.CitySearch {...CreateJobFields.city} />
                          <FormFields.Dropdown
                            {...CreateJobFields.work_environment}
                          />
                          <FormFields.Dropdown {...CreateJobFields.type} />
                          <FormFields.Dropdown
                            {...CreateJobFields.industry}
                            {...{
                              options: industryOptions,
                              onInputChangeHandler: onIndustryChangeHandler,
                            }}
                          />
                          <FormFields.Dropdown
                            {...CreateJobFields.skills}
                            {...{
                              options: skillOptions,
                              onInputChangeHandler: onSkillsChangeHandler,
                            }}
                          />
                          <div>
                            <FormFieldCommon.Label content="Minimum Experience (Years)*" />
                            <Row>
                              <Col xs={6} md={6} xl={5}>
                                <FormFields.Input
                                  {...CreateJobFields.experience_from}
                                />
                              </Col>
                              <Col xs={6} md={6} xl={5}>
                                <FormFields.Input
                                  {...CreateJobFields.experience_to}
                                />
                              </Col>
                            </Row>
                          </div>
                          <Row>
                            <Col>
                              <FormFieldCommon.Label content="Compensation*" />
                              <Row>
                                <Col xs={6} md={6} xl={5}>
                                  <FormFields.CurrencySelector
                                    {...CreateJobFields.compensation_from}
                                  />
                                </Col>
                                <Col xs={6} md={6} xl={5}>
                                  <FormFields.CurrencySelector
                                    {...CreateJobFields.compensation_to}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <FormFields.Dropdown
                            {...CreateJobFields.compensation_type}
                          />
                          <FormFields.Input {...CreateJobFields.department} />
                          {screen.md && Additional}
                        </Col>
                        <Col className="d-none d-lg-block" lg={1} />
                        <Col md={6} className="right-side">
                          <Row>
                            <Col>
                              <FormFields.RichTextArea
                                {...CreateJobFields.job_description}
                                minHeight={screen.md ? 700 : 300}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          sm={12}
                          className="left-side"
                          style={{ marginTop: "24px" }}
                        >
                          {!screen.md && Additional}
                        </Col>
                      </Row>
                      {_.add_qualifying_question && questionComponent}
                    </CreateJobStyles.CreateJobFormContainer>
                    <CreateJobStyles.NextButtonWrapper>
                      <Buttons
                        label="Next"
                        variant="primary"
                        isDisable={
                          isAPICallInProgress ||
                          (submitClicked && !formik.isValid)
                        }
                        handleClick={() => {
                          console.log("VALUES", formik.values);
                          setSubmitClicked(true);
                          formikSubmitHandler({
                            formFields: CreateJobFields,
                            formik,
                            onSubmitHandler,
                            idPrefix,
                          });
                        }}
                      />
                    </CreateJobStyles.NextButtonWrapper>
                  </Fragment>
                );
              }}
            </Formik>
          </CreateJobStyles.CreateJobFormWrapper>
        </CreateJobStyles.CreateJobContainer>
      </CreateJobStyles.PageContainer>
    </CreateJobContext.Provider>
  );
};
