import { Form, FormGroup } from "react-bootstrap";
import { Field, FieldProps } from "formik";
import styled from "styled-components";

import { FormFieldCommon } from "../FormFieldsCommon";
import { FormFieldsProps } from "../FormFields";
import { FIELD_CSS } from "../FormFieldsStyles/common";

export const RadioGroup = (props: FormFieldsProps.RadioGroup) => {
  const {
    fieldName,
    idPrefix,
    label,
    isRequired,
    errorMessage,
    options,
    disabled = false,
    showEveryThingInRow = false,
    showOptionsInRow = showEveryThingInRow,
    needLabelPlaceholder = false,
    showLabel = true,
  } = props;

  return (
    <Field name={fieldName} key={fieldName}>
      {({ form, meta }: FieldProps) => {
        const { setFieldValue, setFieldTouched } = form;
        const { touched, value: selectedValue } = meta;

        const hasError = isRequired && touched && !selectedValue;
        const errorLabel = errorMessage || `${label || fieldName} is required`;
        const showErrorLabel = hasError && errorLabel;

        return (
          <FormGroup className="form-group">
            {!label && needLabelPlaceholder && (
              <FormFieldCommon.LabelPlaceHolder />
            )}
            <RadioLabelAndFieldWrapper
              showEveryThingInRow={showEveryThingInRow}
            >
              {showLabel && label && (
                <FormFieldCommon.Label
                  content={`${label}${isRequired ? "*" : ""}`}
                />
              )}
              <RadioContainer
                id={`${idPrefix}_${fieldName}`}
                disabled={disabled}
                showOptionsInRow={showOptionsInRow}
              >
                {options.map(({ label, value }) => (
                  <Form.Label
                    key={value}
                    className="custom-control custom-radio"
                  >
                    <Form.Control
                      type="radio"
                      className="custom-control-input"
                      name={`${fieldName}_${value}`}
                      value={value}
                      onChange={(event) => {
                        setFieldValue(fieldName, event.target.value);
                        setFieldTouched(fieldName, true);
                      }}
                      checked={value === selectedValue}
                      disabled={disabled}
                    />
                    <span className="custom-control-label">{label}</span>
                  </Form.Label>
                ))}
              </RadioContainer>
            </RadioLabelAndFieldWrapper>
            {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
          </FormGroup>
        );
      }}
    </Field>
  );
};

const RadioContainer = styled.div<{
  disabled: boolean;
  showOptionsInRow: boolean;
}>`
  ${FIELD_CSS.CHECKBOX_RADIO_SWITCH_COMMON};
  .custom-control {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
  .custom-control-label {
    ${FIELD_CSS.CHECKBOX_RADIO_SWITCH_LABEL};
  }
`;
const RadioLabelAndFieldWrapper = styled.div<{ showEveryThingInRow: boolean }>`
  display: flex;
  flex-direction: ${({ showEveryThingInRow }) =>
    showEveryThingInRow ? "row" : "column"};
  gap: ${({ showEveryThingInRow }) => (showEveryThingInRow ? "20px" : "0")};
`;
