import { Button } from "react-bootstrap";
import styled from "styled-components";

export const ButtonWrapper = styled(Button)`
  label {
    cursor: pointer;
  }
`;
export const ErrorLabel = styled.div`
  width: 100%;
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.secondary_hover};
  ${({ theme }) => theme.text.authInputErrorMsg}
`;
