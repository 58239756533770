import { useEffect } from "react";
import { getJobDashboard } from "src/API/Dashboard";
import { actions, useAppDispatch } from "src/Redux";
import { Typography } from "src/components/Basic";
import {
  CardGroup,
  WelcomeCard,
  CandidateApplicationChart,
  HiringProgress,
} from "src/components/Dashboard";
import { colors } from "src/components/Theme/Colors";
import styled from "styled-components";

const Dashboard = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    getJobDashboard(1234)
      .then((res) => {
        dispatch(actions.dashboard.setDashboardData(res.data));
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <DashboardPageContainer>
      <Heading>
        <Typography
          content="DASHBOARD"
          color="black90"
          styleName="dashboardLabel"
        />
      </Heading>
      <Container>
        <WelcomeCard />
        <CardGroup />
      </Container>
      <CandidateApplicationChart />
      <HiringProgress />
    </DashboardPageContainer>
  );
};

export default Dashboard;

const DashboardPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 40px auto 108px;
  @media (min-width: 992px) {
    width: 972px;
    margin: 40px auto 108px;
  }
  @media (min-width: 1440px) {
    width: 1200px;
    gap: 40px;
    margin: 40px auto 108px;
  }
  @media (max-width: 991px) {
    margin: 7px 16px 152px;
    gap: 20px;
  }
`;

const Heading = styled.div`
  @media (max-width: 991px) {
    margin-left: 4px;
    label {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .dark-theme & label {
    color: ${colors.black10};
  }
`;
const Container = styled.div`
  display: flex;
  gap: 32px;
  @media (max-width: 1439px) {
    flex-direction: column;
    gap: 24px;
  }
`;
