export const EndComma = () => (
  <svg
    width="26"
    height="20"
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.83938 18.12C6.25271 18.8667 5.61271 19.2933 4.91938 19.4C4.27938 19.5067 3.71938 19.4 3.23938 19.08C2.75938 18.7067 2.49271 18.2 2.43938 17.56C2.38604 16.8667 2.67938 16.12 3.31938 15.32C4.27938 14.0933 4.91938 12.8667 5.23938 11.64C5.61271 10.4133 5.79938 9.32 5.79938 8.36L6.11938 10.44C4.51938 10.44 3.21271 9.98666 2.19938 9.08C1.23938 8.12 0.759375 6.86666 0.759375 5.32C0.759375 3.82666 1.21271 2.62666 2.11938 1.72C3.02604 0.759996 4.25271 0.279996 5.79938 0.279996C7.39938 0.279996 8.65271 0.81333 9.55938 1.88C10.5194 2.94666 10.9994 4.49333 10.9994 6.52C10.9994 7.69333 10.866 8.92 10.5994 10.2C10.386 11.48 9.98604 12.7867 9.39938 14.12C8.81271 15.4533 7.95938 16.7867 6.83938 18.12ZM21.1594 18.12C20.5727 18.8667 19.9327 19.2933 19.2394 19.4C18.5994 19.5067 18.0394 19.4 17.5594 19.08C17.0794 18.7067 16.8127 18.2 16.7594 17.56C16.706 16.8667 16.9994 16.12 17.6394 15.32C18.5994 14.0933 19.2394 12.8667 19.5594 11.64C19.9327 10.4133 20.1194 9.32 20.1194 8.36L20.4394 10.44C18.8394 10.44 17.5327 9.98666 16.5194 9.08C15.5594 8.12 15.0794 6.86666 15.0794 5.32C15.0794 3.82666 15.5327 2.62666 16.4394 1.72C17.346 0.759996 18.5727 0.279996 20.1194 0.279996C21.7194 0.279996 22.9727 0.81333 23.8794 1.88C24.8394 2.94666 25.3194 4.49333 25.3194 6.52C25.3194 7.69333 25.186 8.92 24.9194 10.2C24.706 11.48 24.306 12.7867 23.7194 14.12C23.1327 15.4533 22.2794 16.7867 21.1594 18.12Z"
      fill="white"
    />
  </svg>
);
