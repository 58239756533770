import { faPlus } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import {
  Buttons,
  DarkModeButton,
  IconButton,
  LinkButton,
  TableButton,
} from "../Basic";
import { Variants } from "../Basic/Buttons/Buttons";

const Button = () => {
  const buttonData: Array<keyof Variants> = [
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "light",
  ];
  const handleClick = () => {
    console.log("hello");
  };
  return (
    <>
      <DarkModeButton />
      <p className="mt-5 fs-4 fw-bold">Default Button</p>
      <Container>
        {buttonData.map((buttonVariant) => (
          <Buttons
            label={buttonVariant}
            variant={buttonVariant}
            isDisable={false}
            handleClick={handleClick}
          />
        ))}
      </Container>
      <p className="mt-5 fs-4 fw-bold">Disable Button</p>
      <Container>
        {buttonData.map((buttonVariant) => (
          <Buttons
            label={buttonVariant}
            variant={buttonVariant}
            isDisable={true}
            handleClick={handleClick}
          />
        ))}
      </Container>
        <p className="mt-5 fs-4 fw-bold">Link Button</p>
      <Container>
        <LinkButton
          isDisable={false}
          handleClick={handleClick}
          label="Button"
        />
      </Container>
        <p className="mt-5 fs-4 fw-bold">Disable Link Button</p>
      <Container>
        <LinkButton isDisable={true} handleClick={handleClick} label="Button" />
      </Container>
        <p className="mt-5 fs-4 fw-bold">Active Table Button </p>
      <Container>
        <TableButton handleClick={handleClick} isActive={true} label="Button" />
      </Container>
        <p className="mt-5 fs-4 fw-bold">Default Table Button</p>
      <Container>
        <TableButton
          handleClick={handleClick}
          isActive={false}
          label="Button"
        />
      </Container>
        <p className="mt-5 fs-4 fw-bold">disable Table Button</p>
      <Container>
        <TableButton
          isDisable={true}
          handleClick={handleClick}
          isActive={false}
          label="Button"
        />
      </Container>
        <p className="mt-5 fs-4 fw-bold">Right Icon Button</p>
      <Container>
        <IconButton
          handleClick={() => console.log("hello")}
          icon={faPlus}
          position="right"
          label="Create Job"
        />
      </Container>
        <p className="mt-5 fs-4 fw-bold">Left Icon Button </p>
      <Container>
        <IconButton
          handleClick={() => console.log("hello")}
          icon={faPlus}
          position="left"
          label="Create Job"
        />
      </Container>
        <p className="mt-5 fs-4 fw-bold">disable Icon Button </p>
      <Container>
        <IconButton
          handleClick={() => console.log("hello")}
          isDisable={true}
          icon={faPlus}
          position="left"
          label="Create Job"
        />
      </Container>
    </>
  );
};

export default Button;
const Container = styled.div`
  display: flex;
  gap: 10px;
  margin: 5px;
`;
