const Envelope = () => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6665 0.333313H2.33317C1.414 0.333313 0.666504 1.08081 0.666504 1.99998V12C0.666504 12.9191 1.414 13.6666 2.33317 13.6666H15.6665C16.5857 13.6666 17.3332 12.9191 17.3332 12V1.99998C17.3332 1.08081 16.5857 0.333313 15.6665 0.333313ZM15.6665 1.99998V2.42581L8.99984 7.61165L2.33317 2.42665V1.99998H15.6665ZM2.33317 12V4.53665L8.48817 9.32415C8.63409 9.43877 8.81428 9.50107 8.99984 9.50107C9.1854 9.50107 9.36558 9.43877 9.5115 9.32415L15.6665 4.53665L15.6682 12H2.33317Z"
        fill="#323251"
      />
    </svg>
  );
};

export default Envelope;
