import { useContext, useState } from "react";
import CountrySelect from "react-bootstrap-country-select";
import styled from "styled-components";
import "./react-bootstrap-country-select.css";

import { Field, FieldProps } from "formik";
import { FormFieldsProps } from "../../FormFields";
import { FormFieldCommon } from "../../FormFieldsCommon";
import { FIELDS } from "../../FormFieldsStyles/common";
import { COUNTRIES } from "./utils";
import { useAppDispatch, actions } from "src/Redux";
import { AppContext } from "src/App";

export const getCountryFromId = (id: string) => {
  return COUNTRIES[id.toLowerCase()];
};

export const CountrySelector = (props: FormFieldsProps.CountrySelector) => {
  const {
    fieldName,
    idPrefix,
    isRequired,
    disabled = false,
    errorMessage,
    label,
    placeholder = label || fieldName,
    needLabelPlaceholder = false,
    showLabel = true,
    citySearchName,
  } = props;
  const { isDarkTheme } = useContext(AppContext);
  const dispatch = useAppDispatch();

  const [textValue, setTextValue] = useState("");
  const [isFocused, setFocused] = useState(false);

  return (
    <Field name={fieldName} key={fieldName}>
      {({ meta, form }: FieldProps<FormFieldsProps.Country>) => {
        const { touched, value } = meta;
        const { setFieldValue, setFieldTouched } = form;

        const hasError = !isFocused && isRequired && touched && !value;
        let errorLabel = errorMessage || `${label || fieldName} is required`;
        if (textValue) {
          errorLabel = "please select a country";
        }
        const showErrorLabel = hasError && errorLabel;

        return (
          <div>
            {showLabel && label && (
              <FormFieldCommon.Label
                content={`${label}${isRequired ? "*" : ""}`}
              />
            )}
            {!label && needLabelPlaceholder && (
              <FormFieldCommon.LabelPlaceHolder />
            )}
            <CountrySelectorContainer
              onFocus={() => setFocused(true)}
              disabled={disabled}
              Error={hasError}
              isDarkTheme={isDarkTheme}
              id={`${idPrefix}_${fieldName}`}
              onBlur={() => {
                setFocused(false);
                !touched && setFieldTouched(fieldName, true);
                const inputElement = document.getElementsByClassName(
                  "country-select__form-control"
                )[0] as unknown as HTMLInputElement;
                if (inputElement?.value) {
                  setTextValue(inputElement.value);
                }
              }}
            >
              <CountrySelect
                value={value}
                onChange={(value) => {
                  setFieldValue(fieldName, value);
                  setFieldValue(citySearchName, null);
                  dispatch(actions.common.setCities([]));
                }}
                onTextChange={() => {}}
                matchNameFromStart={false}
                placeholder={placeholder}
              />
            </CountrySelectorContainer>
            {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
          </div>
        );
      }}
    </Field>
  );
};

const CountrySelectorContainer = styled.div<{
  disabled: boolean;
  Error: boolean;
  isDarkTheme: boolean;
}>`
  ${({ Error }) => (Error ? FIELDS.ERROR_FORM_CONTROL : FIELDS.FORM_CONTROL)};
`;
