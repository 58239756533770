import styled, { css } from "styled-components";

import { FIELDS, FIELD_COLORS } from "../../FormFieldsStyles/common";

//#region Input
const inputStyle = css`
  ${FIELDS.FORM_CONTROL};
  .form-control {
    text-align: center;
  }
`;
const disabledInputStyle = css`
  ${inputStyle};
  .form-control {
    cursor: not-allowed;
  }
`;
//#endregion

export const InputWithIncrementButtonContainer = styled.div<{
  disabled: boolean;
}>`
  ${({ disabled }) => (disabled ? disabledInputStyle : inputStyle)};
  .input-field-icon {
    background: ${({ theme }) => theme.colors.primary_default};
    border-color: ${FIELD_COLORS.FIELD_BORDER_COLOR};
  }
  .input-field-icon-left {
    border-radius: 5px 0px 0px 5px;
  }
  .input-field-icon-right {
    border-radius: 0px 5px 5px 0px;
  }
`;
