import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { Grid } from "antd";

import "./otp.css";
import { Typography, TypographyLink } from "src/components/Basic";
import { OTPStyles } from "./OTPStyles";
import { actions, useAppDispatch, useAppSelector } from "src/Redux";
import { API_CALLS } from "src/API_CALLS";

export const OTP = () => {
  const screen = Grid.useBreakpoint();
  const { OTP, validationFromAPI, email, OTPInitiatedFrom } = useAppSelector(
    (store) => store.OTP
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const subTitleStyle = screen.lg ? "otpSubTitle" : "otpSubTitleMobile";

  return (
    <OTPStyles.Container>
      <OTPStyles.Title>
        <Typography
          content="Check your email for a verification code"
          styleName={screen.lg ? "otpTitle" : "otpTitleMobile"}
          color="primary_default"
          className="otp-title"
        />
      </OTPStyles.Title>
      <OTPStyles.Subtitle>
        <OTPStyles.Line1>
          <Typography
            content={`An email has been sent to`}
            styleName={subTitleStyle}
            color="black80"
            className="otp-subtitle"
          />
          <TypographyLink
            content={email}
            styleName={subTitleStyle}
            color="black80"
            className="otp-subtitle"
          />
        </OTPStyles.Line1>
        <Typography
          content={`Please check your email and enter the One-Time Pin`}
          styleName={subTitleStyle}
          color="black80"
          className="otp-subtitle"
        />
      </OTPStyles.Subtitle>
      <div className="otp-input-wrapper">
        <OtpInput
          value={OTP}
          onChange={(otp) => {
            const OTP = otp.toLocaleUpperCase();
            dispatch(actions.OTP.setOTP(OTP));
            if (OTP.length === 6) {
              if (OTPInitiatedFrom === "register")
                API_CALLS.AUTH.otpVerificationAfterRegister(navigate);
              if (OTPInitiatedFrom === "login")
                API_CALLS.AUTH.otpVerificationAfterLogin(navigate);
            }
          }}
          numInputs={6}
          renderInput={(props) => <input {...props} />}
          shouldAutoFocus={true}
        />
      </div>
      {!validationFromAPI && (
        <Typography
          content="Can’t find your code? Check your spam folder!"
          styleName={screen.lg ? "otpText" : "otpTextMobile"}
          color="black70"
          className="otp-subtitle"
        />
      )}
      {validationFromAPI && (
        <OTPStyles.ErrorMessageWrapper>
          <Typography
            content={
              validationFromAPI || "That code wasn’t valid. Give it another go!"
            }
            styleName={screen.lg ? "otpText" : "otpTextMobile"}
            color="black100"
            className="otp-subtitle"
          />
        </OTPStyles.ErrorMessageWrapper>
      )}
      <OTPStyles.ResendLinkWrapper>
        <Typography
          content="or&nbsp;"
          styleName="otpTextLink"
          color="primary_default"
          className="otp-link-text"
        />
        <TypographyLink
          content="Send New Code"
          styleName="otpTextLink"
          color="primary_default"
        />
      </OTPStyles.ResendLinkWrapper>
    </OTPStyles.Container>
  );
};
