import { createSlice } from "@reduxjs/toolkit";
import { PipelinePhaseSlice } from "src/components/Pipeline/PipeLine";

const initialState: PipelinePhaseSlice = {
  job_title: "",
  date_created: "",
  pipeline_overview_count: {
    "-1": 0,
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  },
};

const pipelinePhaseSlice = createSlice({
  name: "pipelinePhase",
  initialState,
  reducers: {
    incrementPhase: (_state, action) => {
      return { ...action.payload };
    },
  },
});

export const pipelinePhaseActions = pipelinePhaseSlice.actions;
export const pipelinePhaseReducer = pipelinePhaseSlice.reducer;
