import styled from "styled-components";
import { Button } from "react-bootstrap";

const EmailConfirmedContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.success_default}25;
  border: 1px solid ${({ theme }) => theme.colors.success_outline};
  padding: 10px 29px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  max-width: 337px;
  margin: auto;
  label {
    margin-bottom: 0;
  }
  margin-bottom: 18px;
  @media (min-width: 992px) {
    margin-bottom: 32px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  a label {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  div {
    display: flex;
    justify-content: center;
  }

  @media (min-width: 992px) {
    gap: 6px;
  }
`;
const InputFieldWrapper = styled.div`
  max-width: 328px;
  margin: 36px auto 30px;

  @media (min-width: 992px) {
    min-width: 348px;
    margin: 68px auto 81px;
  }
`;
const ButtonWrapper = styled(Button)`
  width: 100%;
  margin-top: 24px;
  label {
    cursor: pointer;
  }
  @media (min-width: 992px) {
    margin-top: 16px;
  }
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

export const SignInStyles = {
  EmailConfirmedContainer,
  Container,
  InputFieldWrapper,
  ButtonWrapper,
  InputContainer,
};
