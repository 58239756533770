import { Form, FormGroup } from "react-bootstrap";
import { Field, FieldProps } from "formik";
import styled from "styled-components";

import { FormFieldCommon } from "../FormFieldsCommon";
import { FormFieldsProps } from "../FormFields";
import { FIELD_CSS } from "../FormFieldsStyles/common";

export const Checkbox = (props: FormFieldsProps.Checkbox) => {
  const {
    fieldName,
    idPrefix,
    label,
    isRequired,
    errorMessage,
    disabled = false,
    needLabelPlaceholder = false,
    helpText,
  } = props;

  return (
    <Field name={fieldName} key={fieldName}>
      {({ meta, form }: FieldProps) => {
        const { setFieldTouched, setFieldValue } = form;
        const { touched, value = false } = meta;

        const hasError = isRequired && touched && !value;
        const errorLabel = errorMessage || `${label || fieldName} is required`;
        const showErrorLabel = hasError && errorLabel;

        return (
          <CheckboxContainer>
            <FormGroup className="form-group">
              {needLabelPlaceholder && <FormFieldCommon.LabelPlaceHolder />}
              <Form.Label
                key={value}
                className="custom-control custom-checkbox"
              >
                <Form.Control
                  id={`${idPrefix}_${fieldName}`}
                  type="checkbox"
                  className="custom-control-input"
                  name={`${fieldName}_${value}`}
                  onChange={() => {
                    setFieldValue(fieldName, !value);
                    setFieldTouched(fieldName, true);
                  }}
                  value={value}
                  disabled={disabled}
                  checked={value}
                />
                <span className="custom-control-label component-label">
                  {label}
                </span>
                <FormFieldCommon.InfoWrapper>
                  {helpText?.title && (
                    <FormFieldCommon.Info helpText={helpText} />
                  )}
                </FormFieldCommon.InfoWrapper>
              </Form.Label>
              {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
            </FormGroup>
          </CheckboxContainer>
        );
      }}
    </Field>
  );
};

export const CheckboxGroup = (props: FormFieldsProps.CheckboxGroup) => {
  const {
    fieldName,
    idPrefix,
    label,
    isRequired,
    errorMessage,
    options,
    disabled = false,
    showEveryThingInRow = false,
    showOptionsInRow = showEveryThingInRow,
    needLabelPlaceholder = false,
    showLabel = true,
  } = props;

  return (
    <Field name={fieldName} key={fieldName}>
      {({ form, meta }: FieldProps) => {
        const checkedItemsMap = meta.value || {};

        const { setFieldValue, setFieldTouched } = form;
        const { touched } = meta;

        const isCheckedItemsEmpty = Object.keys(checkedItemsMap).every(
          (key) => checkedItemsMap[key] === false
        );
        const hasError = isRequired && touched && isCheckedItemsEmpty;
        const errorLabel = errorMessage || `${label || fieldName} is required`;
        const showErrorLabel = hasError && errorLabel;

        return (
          <FormGroup className="form-group">
            {!label && needLabelPlaceholder && (
              <FormFieldCommon.LabelPlaceHolder />
            )}
            <CheckBoxGroupLabelAndFieldWrapper
              showEveryThingInRow={showEveryThingInRow}
            >
              {showLabel && label && (
                <FormFieldCommon.Label
                  content={`${label}${isRequired ? "*" : ""}`}
                />
              )}
              <CheckboxGroupContainer
                id={`${idPrefix}_${fieldName}`}
                disabled={disabled}
                showOptionsInRow={showOptionsInRow}
              >
                {options.map(({ label, value }) => (
                  <Form.Label
                    key={value}
                    className="custom-control custom-checkbox"
                  >
                    <Form.Control
                      type="checkbox"
                      className="custom-control-input"
                      name={`${fieldName}_${value}`}
                      onChange={() => {
                        checkedItemsMap[value] = checkedItemsMap[value]
                          ? false
                          : true;
                        setFieldValue(fieldName, checkedItemsMap);
                        setFieldTouched(fieldName, true);
                      }}
                      value={value}
                      disabled={disabled}
                      checked={checkedItemsMap[value]}
                    />
                    <span className="custom-control-label">{label}</span>
                  </Form.Label>
                ))}
              </CheckboxGroupContainer>
            </CheckBoxGroupLabelAndFieldWrapper>
            {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
          </FormGroup>
        );
      }}
    </Field>
  );
};

const CheckboxGroupContainer = styled.div<{
  disabled: boolean;
  showOptionsInRow: boolean;
}>`
  ${FIELD_CSS.CHECKBOX_RADIO_SWITCH_COMMON};
  .custom-control {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
  .custom-control-label {
    ${FIELD_CSS.CHECKBOX_RADIO_SWITCH_LABEL};
  }
  .dark-theme .custom-control-label {
    color: red;
  }
  .custom-control-label::before {
    top: 1px;
  }
`;
const CheckBoxGroupLabelAndFieldWrapper = styled.div<{
  showEveryThingInRow: boolean;
}>`
  display: flex;
  flex-direction: ${({ showEveryThingInRow }) =>
    showEveryThingInRow ? "row" : "column"};
  gap: ${({ showEveryThingInRow }) => (showEveryThingInRow ? "20px" : "0")};
`;
const CheckboxContainer = styled.div`
  ${FIELD_CSS.CHECKBOX_COMPONENT_LABEL};
  .form-label {
    display: flex;
    flex-direction: row;
    gap: 13px;
    width: fit-content;
    cursor: pointer;
  }
`;
