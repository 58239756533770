import styled from "styled-components";
import { Pagination } from "react-bootstrap";
import { theme } from "src/components/Theme";

export const CandidateListContainer = styled.div`
  .candidate-list {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
  }
`;
export const CandidateContainer = styled.div<{
  isEmailOpen: boolean;
  isSelected: boolean;
}>`
  display: flex;
  background: ${({ isEmailOpen }) =>
    isEmailOpen ? theme.colors.light_default : theme.colors.grey_light};
  padding: 14px;
  height: 68px;
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected &&
    ` border: 1px solid ${theme.colors.success_20};
      box-shadow: ${theme.Effects.candidateBoxShadow};
      filter: ${theme.Effects.candidateDropShadow};`};
  &:hover {
    border: 1px solid ${theme.colors.success_20};
    box-shadow: ${theme.Effects.candidateBoxShadow};
    filter: ${theme.Effects.candidateDropShadow};
  }
  label {
    cursor: pointer;
  }
`;
export const JobTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const JobTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
export const MainContainer = styled.div`
  padding: 12px 6px 32px 6px;
  background: ${theme.colors.white_pure};
  width: fit-content;
  @media (max-width: 991px) {
    width: auto;
    padding: 0px 6px 32px;
  }
  @media (min-width: 1440px) {
    padding: 16px 21px 59px 16px;
  }
  .dark-theme & {
    background: ${theme.colors.black110};
  }
  .latest-dropdown + .dropdown-menu.show {
    top: 10px !important;
    left: -70px !important;
  }
  .show-dropdown + .dropdown-menu.show {
    top: 10px !important;
    left: -20px !important;
    &::after {
      left: 20% !important;
    }
  }
  .onbording-dropdown .dropdown-menu.show {
    top: 10px !important;
    left: -40px !important;
  }
`;
export const HeadingContainer = styled.div`
  display: flex;
  margin: 0px 1px 12px 2px;
  gap: 20px;
  @media (min-width: 1440px) {
    margin: 0px 8px 48px 9px;
    gap: 25px;
  }
  @media (max-width: 991px) {
    margin: 0px 5px 12px 10px;
    flex-direction: column;
    gap: 7px;
  }
  .onbording-dropdown {
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
  }
  label {
    @media (max-width: 991px) {
      ${theme.text.questionairre}
      color: ${theme.colors.primary_default};
    }
  }
  .btn-label {
    font-weight: 600;
  }
  .show-dropdown {
    &,
    &:focus,
    &:active,
    &:hover {
      @media (min-width: 992px) {
        background: ${theme.colors.primary_default} !important;
        color: ${theme.colors.white_pure} !important;
      }
    }
  }
  .dark-theme & {
    label {
      @media (max-width: 991px) {
        color: ${theme.colors.primary_default};
      }
      color: ${theme.colors.black50};
    }
    .latest-dropdown {
      &,
      &:focus,
      &:active,
      &:hover {
        background: ${theme.colors.light_default} !important;
        color: ${theme.colors.black100} !important;
      }
    }
    .show-dropdown {
      &,
      &:focus,
      &:active,
      &:hover {
        @media (min-width: 992px) {
          background: ${theme.colors.primary_default} !important;
          color: ${theme.colors.white_pure} !important;
        }
      }
    }
  }
`;
export const Photo = styled.img`
  width: 40px;
  height: 40px;
  border: 4.28571px solid ${theme.colors.white_pure};
  box-shadow: ${theme.Effects.photoBorderShadow};
  border-radius: 8.57143px;
`;
export const PaginationItem = styled(Pagination.Item)`
  a {
    ${theme.text.tableButton}
  }
  &.indicator a {
    padding: 9px 16px;
    width: fit-content;
    margin: 0px;
    white-space: nowrap !important;
  }
  .dark-theme & {
    background: none !important;
  }
`;
export const PageItem = styled(Pagination.Item)`
  white-space: nowrap;
  &.indicator {
    a {
      background: transparent !important;
      border: none;
      color: ${theme.colors.black100};
      ${theme.text.tableButton}
    }
    &.indicator a {
      padding: 9px 16px;
      width: fit-content;
      margin: 0px;
    }
    &.indicator a:hover {
      background: transparent !important;
      border: none !important;
    }
  }
  &.disabled {
    cursor: not-allowed;
  }
  .dark-theme &.disabled a {
    color: ${theme.colors.white_pure} !important;
    opacity: 0.5;
  }
`;
export const Paginations = styled(Pagination)`
  display: flex;
  justify-content: center;
  margin-top: 31px;
`;
export const DropDownTitle = styled.div`
  @media (min-width: 992px) {
    width: 63px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
