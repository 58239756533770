export const Increment = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3_1390)">
      <path
        d="M10.1385 18.3333C14.7409 18.3333 18.4718 14.6023 18.4718 9.99996C18.4718 5.39759 14.7409 1.66663 10.1385 1.66663C5.53614 1.66663 1.80518 5.39759 1.80518 9.99996C1.80518 14.6023 5.53614 18.3333 10.1385 18.3333Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1387 6.66663V13.3333"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.80518 10H13.4718"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3_1390">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.138672)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const Decrement = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3_1383)">
      <path
        d="M10.1385 18.3333C14.7409 18.3333 18.4718 14.6023 18.4718 9.99996C18.4718 5.39759 14.7409 1.66663 10.1385 1.66663C5.53614 1.66663 1.80518 5.39759 1.80518 9.99996C1.80518 14.6023 5.53614 18.3333 10.1385 18.3333Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.80518 10H13.4718"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3_1383">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.138672)"
        />
      </clipPath>
    </defs>
  </svg>
);
