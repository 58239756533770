import { JobProps } from "./Job";
import { createSlice } from "@reduxjs/toolkit";

const initialState: JobProps.State = {
  CreateNovaJobForm: {} as JobProps.CreateNovaJobForm,
  isAPICallInProgress: false,
};

const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    setCreateNovaJob: (state, action: JobProps.setCreateNovaJobForm) => {
      state.CreateNovaJobForm = action.payload;
    },
    setAPICallInProgress: (state, action: JobProps.setAPICallInProgress) => {
      state.isAPICallInProgress = action.payload;
    },
  },
});

export const jobReducer = jobSlice.reducer;
export const jobActions = jobSlice.actions;
