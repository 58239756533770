import { theme } from "src/components/Theme";
import styled, { css } from "styled-components";

export const filledTextValue = css`
  ${theme.text.authInputText};
  color: ${theme.colors.black80};
`;

export const fieldHover = css`
  border-bottom: 1px solid ${theme.colors.primary_default} !important;
`;

export const dropdownStyle = css`
  .select__value-container {
    padding: 0px 0px 8px !important;
  }
  .select__input-container input {
    color: ${theme.colors.black80} !important;
  }
  .select__placeholder label {
    color: ${theme.colors.black50} !important;
    ${theme.text.authInputText}
  }
  .select__control {
    background: transparent !important;
    border: none !important;
    border-bottom: 1px solid ${theme.colors.primary_default} !important;
    border-radius: 0px !important;
    outline: none !important;
    cursor: pointer !important;
    ${filledTextValue};
  }
  .select__single-value {
    ${filledTextValue};
  }
  .css-1u9des2-indicatorSeparator {
    display: none;
  }
  .css-1xc3v61-indicatorContainer {
    color: ${theme.colors.primary_default} !important;
  }
  .css-15lsz6c-indicatorContainer {
    color: ${theme.colors.primary_default} !important;
  }

  .select__control:active {
    ${fieldHover};
  }
  .select__control:focus {
    ${fieldHover};
  }

  .select__control--menu-is-open {
    outline: 0px !important;
    border: 0px !important;
  }
  .select__option {
    ${theme.text.authDropDownText}
    color: ${theme.colors.black90};
  }

  .select__option:hover {
    ${theme.text.authDropDownTextHover}
    color: ${theme.colors.primary_default};
    background: ${theme.colors.grey_input_dropdown_hover};
    cursor: pointer !important;
  }
  .select__input-container input::placeholder {
    ${theme.text.authInputText}
    color: ${theme.colors.primary_default} !important;
  }
  .css-t3ipsp-control {
    box-shadow: none !important;
    border-bottom: 1px solid ${theme.colors.primary_default} !important;
  }
  .css-t3ipsp-control:hover {
    box-shadow: none !important;
    border-bottom: 1px solid ${theme.colors.primary_default} !important;
  }
  .select__option--is-selected {
    color: ${theme.colors.white_pure};
  }
`;

export const DropdownContainer = styled.div<{
  disabled: boolean;
  Error: boolean;
}>`
  ${dropdownStyle};

  #react-select-3-listbox {
    border: 1px solid ${theme.colors.primary_default} !important;
    box-shadow: 0px 1px 2px rgba(21, 21, 21, 0.02),
      0px 4px 8px rgba(21, 21, 21, 0.08);
    border-radius: 4px;
  }
`;
