import React from "react";
import clsx from "clsx";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import {
  Cell,
  Column,
  ColumnInstance,
  HeaderGroup,
  Row,
  TableState,
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";

import style from "./Table.module.css";
import { CustomTable, TableProps } from "./Table";
import { colors } from "../../Theme/Colors";
import { FormFields } from "../../Basic";

const pageJumperOptions = [
  { value: 1, label: "1" },
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 15, label: "15" },
];
export const Table: React.FC<TableProps.IProps> = ({
  data,
  columns,
  customRowCell,
  customColumnCell,
  tablefilter,
}) => {
  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    gotoPage,
    pageCount,
    pageSize,
    setGlobalFilter,
    state,
  }: CustomTable.TableOptions<TableProps.Data> = useTable(
    {
      columns,
      data,
      initialState: { pageSize: pageJumperOptions[0].value } as Partial<
        TableState<Column>
      >,
    },
    useGlobalFilter,
    usePagination
  );
  const onChangeHandler = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 300);

  const { globalFilter } = state;
  const [value] = React.useState(globalFilter);

  React.useEffect(() => {
    setGlobalFilter(value || undefined);
  }, [data]);

  return (
    <>
      <div className="table-responsive">
        <div className="d-flex justify-content-between">
          {tablefilter}
          <div className="d-flex gap-2 ">
            <FormFields.SearchField
              fieldName="search"
              idPrefix="table_search_input_field"
              onChangeHandler={onChangeHandler}
              value={value}
              isRequired={false}
            />

            <FormFields.DropdownField
              fieldName="tablePageNumber"
              isRequired={false}
              defaultValue={pageJumperOptions[0]}
              idPrefix="table_page_jumper_filed"
              onChangeHandler={(selectedElement) => {
                setPageSize(selectedElement?.value);
              }}
              value={pageSize}
              options={pageJumperOptions}
            />
          </div>
        </div>
        <table {...getTableProps()} className="table  mb-0">
          <thead>
            {headerGroups.map((headerGroup: HeaderGroup) => (
              <tr
                className={clsx(style.table_header, "table-header")}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column: ColumnInstance) => (
                  <th {...column.getHeaderProps()}>
                    {customColumnCell(column)}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            className={clsx(style.table_body, "table-body")}
            {...getTableBodyProps()}
          >
            {page.map((row: Row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: Cell) => (
                    <td {...cell.getCellProps()}>{customRowCell(cell)}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-end mt-4 mx-4">
        <PaginationButton
          className={clsx(canPreviousPage ? "page-enable" : "page-disable")}
          variant=""
          onClick={() => {
            gotoPage(0);
          }}
          disabled={!canPreviousPage}
        >
          {" |< "}
        </PaginationButton>
        <PaginationButton
          className={clsx(canPreviousPage ? "page-enable" : "page-disable")}
          variant=""
          onClick={() => {
            previousPage();
          }}
          disabled={!canPreviousPage}
        >
          {" < "}
        </PaginationButton>
        <PaginationButton
          className={clsx(canNextPage ? "page-enable" : "page-disable")}
          variant=""
          onClick={() => {
            nextPage();
          }}
          disabled={!canNextPage}
        >
          {" > "}
        </PaginationButton>
        <PaginationButton
          className={clsx(canNextPage ? "page-enable" : "page-disable")}
          variant=""
          onClick={() => {
            gotoPage(pageCount && pageCount - 1);
          }}
          disabled={!canNextPage}
        >
          {" >| "}
        </PaginationButton>
      </div>
    </>
  );
};

export const PaginationButton = styled(Button)`
  color: ${colors.black_pure};
  font-weight: 500;
  font-size: 18px;
  border: none;
  &:disabled {
    color: ${colors.black50};
    border: none;
    cursor: not-allowed;
    pointer-events: visible;
  }
  &:active {
    border: none;
  }
`;
