import { createSlice } from "@reduxjs/toolkit";

import { OTPProps } from "./OTP";

const initialState: OTPProps.State = {
  OTP: "",
  OTPVerificationSuccess: false,
  validationFromAPI: "",
  email: "",
  errorMessageFromAPI: "",
  OTPInitiatedFrom: "register",
  isAPICallInProgress: false,
};

const OTPSlice = createSlice({
  name: "OTP",
  initialState,
  reducers: {
    setOTP: (state, action: OTPProps.setOTP) => {
      state.OTP = action.payload;
    },
    setOTPVerificationSuccess: (
      state,
      action: OTPProps.setOTPVerificationSuccess
    ) => {
      state.OTPVerificationSuccess = action.payload;
    },
    setErrorMessageFromAPI: (
      state,
      action: OTPProps.setErrorMessageFromAPI
    ) => {
      state.errorMessageFromAPI = action.payload || "";
    },
    setValidationFromAPI: (state, action: OTPProps.setValidationFromAPI) => {
      state.validationFromAPI = action.payload;
    },
    setEmail: (state, action: OTPProps.setEmail) => {
      state.email = action.payload;
    },
    setOTPInitiatedFrom: (state, action: OTPProps.setOTPInitiatedFrom) => {
      state.OTPInitiatedFrom = action.payload;
    },
    setAPICallInProgress: (state, action: OTPProps.setAPICallInProgress) => {
      state.isAPICallInProgress = action.payload;
    },
  },
});

export const OTPActions = OTPSlice.actions;
export const OTPReducer = OTPSlice.reducer;
