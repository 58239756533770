import { useContext } from "react";
import clsx from "clsx";

import { DarkModeIcon, LightModeIcon } from "../../../assets/custom_Icons";
import { darkmode } from "../../../utils/theme";
import styles from "../../layout/Header/Header.module.css";
import { AppContext } from "src/App";

const DarkModeButton = () => {
  document.querySelector("body")?.classList.add("app");
  const { setIsDarkTheme } = useContext(AppContext);

  return (
    <div
      className={clsx(
        "new nav-link theme-layout nav-link-bg layout-setting d-flex justify-content-end ",
        styles.darkmode_button
      )}
    >
      <span
        className="dark-layout"
        onClick={() => {
          darkmode();
          setIsDarkTheme((prev) => !prev);
        }}
      >
        <DarkModeIcon />
      </span>
      <span
        className="light-layout"
        onClick={() => {
          darkmode();
          setIsDarkTheme((prev) => !prev);
        }}
      >
        <LightModeIcon />
      </span>
    </div>
  );
};

export { DarkModeButton };
