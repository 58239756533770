import { faCircleUser } from "@fortawesome/free-regular-svg-icons";
import { BriefCase, UserIcon, ViewIcon } from "../assets/custom_Icons";
export const tableData = [
  {
    id: 1,
    photo: faCircleUser,
    job: ["Accountant", "Cape-Town"],
    department: "Finance",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "10 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "drafts",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 2,
    photo: faCircleUser,
    job: [" Database administrators and architects", "Mthatha"],
    department: "Development",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "11 Jan 2023",
    expiresOn: "30 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "drafts",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 3,
    photo: faCircleUser,
    job: ["sales", "Witbank"],
    department: "Technology",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "11 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "drafts",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 4,
    photo: faCircleUser,
    job: [" Computer systems analysts", "Pietermaritzburg"],
    department: "Technology",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "10 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "drafts",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 5,
    photo: faCircleUser,
    job: [" Information security analysts", "George"],
    department: "Technology",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "10 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "archived",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 6,
    photo: faCircleUser,
    job: ["Web developers", "Rustenburg"],
    department: "Technology",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "10 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "archived",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 7,
    photo: faCircleUser,
    job: [" IT project managers", "Kimberley"],
    department: "Technology",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "10 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "archived",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 8,
    photo: faCircleUser,
    job: [" Computer support specialists", "Nelspruit"],
    department: "Technology",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "10 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "archived",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 9,
    photo: faCircleUser,
    job: [" Software developer", "Polokwane"],
    department: "Technology",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "10 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "active",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 10,
    photo: faCircleUser,
    job: ["React Developer", "East London"],
    department: "Technology",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "10 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "active",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 11,
    photo: faCircleUser,
    job: ["Full-stack Developer", "Bloemfontein"],
    department: "Technology",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "10 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "active",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 12,
    photo: faCircleUser,
    job: ["Android Developer", "Durban"],
    department: "Technology",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "10 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "active",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 13,
    photo: faCircleUser,
    job: ["Accountant", "Cape-Town"],
    department: "Finance",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "10 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "drafts",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 14,
    photo: faCircleUser,
    job: [" Database administrators and architects", "Mthatha"],
    department: "Development",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "11 Jan 2023",
    expiresOn: "30 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "drafts",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 15,
    photo: faCircleUser,
    job: ["sales", "Witbank"],
    department: "Technology",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "11 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "drafts",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 16,
    photo: faCircleUser,
    job: [" Computer systems analysts", "Pietermaritzburg"],
    department: "Technology",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "10 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "drafts",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 17,
    photo: faCircleUser,
    job: [" Information security analysts", "George"],
    department: "Technology",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "10 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "archived",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 18,
    photo: faCircleUser,
    job: ["Web developers", "Rustenburg"],
    department: "Technology",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "10 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "archived",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 19,
    photo: faCircleUser,
    job: [" IT project managers", "Kimberley"],
    department: "Technology",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "10 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "archived",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 20,
    photo: faCircleUser,
    job: [" Computer support specialists", "Nelspruit"],
    department: "Technology",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "10 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "archived",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 21,
    photo: faCircleUser,
    job: [" Software developer", "Polokwane"],
    department: "Technology",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "10 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "active",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 22,
    photo: faCircleUser,
    job: ["React Developer", "East London"],
    department: "Technology",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "10 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "active",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 23,
    photo: faCircleUser,
    job: ["Full-stack Developer", "Bloemfontein"],
    department: "Technology",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "10 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "active",
    options: ["Edit", "Settings", "View"],
  },
  {
    id: 24,
    photo: faCircleUser,
    job: ["Android Developer", "Durban"],
    department: "Technology",
    reference: Math.floor(Math.random() * 10000000),
    dateCreated: "10 Jan 2023",
    expiresOn: "28 Mar 2023",
    user: Math.floor(Math.random() * 1000),
    jobs: Math.floor(Math.random() * 1000),
    view: Math.floor(Math.random() * 1000),
    status: "active",
    options: ["Edit", "Settings", "View"],
  },
];

export const tableColumns = [
  { Header: "UserPhoto", accessor: "photo" },
  { Header: "Job", accessor: "job" },
  { Header: "Department", accessor: "department" },
  { Header: "Reference#", accessor: "reference" },
  { Header: "Date Created", accessor: "dateCreated" },
  { Header: "Expires On", accessor: "expiresOn" },
  {
    Header: () => (
      <div>
        <UserIcon height={16} width={16} />
      </div>
    ),
    accessor: "user",
  },
  { Header: () => <BriefCase height={16} width={16} />, accessor: "jobs" },
  { Header: () => <ViewIcon height={16} width={16} />, accessor: "view" },
  { Header: "Status", accessor: "status" },
  { Header: "Options", accessor: "options" },
];
