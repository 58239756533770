export const darkmode = () => {
  document.querySelector(".app")?.classList.toggle("dark-theme");
  document.querySelector(".app")?.classList.remove("light-theme");
};

export const closeSiderBar = () => {
  document.querySelector("body")?.classList.remove("sidenav-toggled");
};

export const openCloseSidebar = () => {
  document.querySelector("body")?.classList.toggle("sidenav-toggled");
};
