import React from "react";
import { Button } from "react-bootstrap";
import { theme } from "../../Theme";
import styled from "styled-components";
import { IButton, Variants, variantProperties } from "./Buttons";
const Buttons: React.FC<IButton.IProps> = ({
  variant,
  label,
  handleClick,
  isDisable = false,
}) => {
  const variantData: Variants = {
    primary: {
      class: "btn-primary",
      backgroundHoverColor: theme.colors.primary_hover,
      backgroundDisableColor: theme.colors.primary_disable,
      backgroundColor: theme.colors.primary_default,
    },
    secondary: {
      class: "btn-secondary",
      backgroundHoverColor: theme.colors.secondary_hover,
      backgroundColor: theme.colors.secondary_default,
      backgroundDisableColor: theme.colors.secondary_disable,
    },
    success: {
      class: "btn-success",
      backgroundHoverColor: theme.colors.success_hover,
      backgroundColor: theme.colors.success_default,
      backgroundDisableColor: theme.colors.success_disable,
    },
    info: {
      class: "btn-info",
      backgroundHoverColor: theme.colors.info_hover,
      backgroundColor: theme.colors.info_default,
      backgroundDisableColor: theme.colors.info_disable,
    },
    warning: {
      class: "btn-warning",
      backgroundHoverColor: theme.colors.warning_hover,
      backgroundColor: theme.colors.warning_default,
      backgroundDisableColor: theme.colors.warning_disable,
    },
    danger: {
      class: "btn-danger",
      backgroundHoverColor: theme.colors.danger_hover_click,
      backgroundColor: theme.colors.danger_default,
      backgroundDisableColor: theme.colors.danger_disable,
    },
    light: {
      class: "btn-light",
      backgroundHoverColor: theme.colors.light_hover,
      backgroundColor: theme.colors.light_default,
      backgroundDisableColor: theme.colors.light_disable,
    },
  };

  return (
    <ButtonContainer
      isDisable={isDisable}
      {...variantData[variant]}
      variant=""
      className={` ${variantData[variant].class} ${
        isDisable ? "disabled" : ""
      }`}
      onClick={() => !isDisable && handleClick()}
      disabled={isDisable}
    >
      {label}
    </ButtonContainer>
  );
};

const ButtonContainer = styled(Button)<
  {
    backgroundHoverColor: string;
    backgroundDisableColor: string;
    isDisable: boolean;
    backgroundColor: string;
  } & variantProperties
>`
  background: ${({ backgroundDisableColor, isDisable, backgroundColor }) =>
    isDisable ? backgroundDisableColor : backgroundColor} !important;

  &:hover {
    background: ${({ backgroundHoverColor }) =>
      backgroundHoverColor} !important;
  }
  cursor: ${({ isDisable }) =>
    isDisable ? "not-allowed" : "pointer"} !important;
`;
export { Buttons };
