import styled from "styled-components";
import { theme } from "src/components/Theme";

export const InBoxContainer = styled.div<{ isOnBoarding: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border: 1px solid ${theme.colors.black20};
  background: ${theme.colors.light_default};
  border-radius: 10px;
  padding: 12px 25px 8px;
  white-space: nowrap;
  cursor: pointer;
  label {
    cursor: pointer;
  }
  ${theme.text.inboxText.md}
  @media (min-width: 1440px) {
    flex-direction: row;
    background: ${({ isOnBoarding }) =>
      isOnBoarding ? theme.colors.grey_brown : theme.colors.light_default};
    border-radius: 41px;
    padding: ${({ isOnBoarding }) =>
      isOnBoarding ? "18px 10px 18px 29px" : "10px 10px 10px 29px"};
    gap: 30px;
    ${theme.text.inboxText.lg}
    label {
      color: ${({ isOnBoarding }) => isOnBoarding && theme.colors.white_pure};
    }
  }
  .dark-theme & {
    border: 1px solid ${theme.colors.black130};
  }
`;
export const CountContainer = styled.div<{ isOnBoarding: boolean }>`
  color: ${theme.colors.black80};
  ${theme.text.countText};
  line-height: 2;
  @media (min-width: 1440px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ isOnBoarding }) => (isOnBoarding ? "31px" : "28px")};
    width: ${({ isOnBoarding }) => (isOnBoarding ? "31px" : "28px")};
    border-radius: 100%;
    border: ${({ isOnBoarding }) =>
      isOnBoarding ? `3px solid ${theme.colors.primary100}` : "none"};
    color: ${theme.colors.yellow10};
    background: ${theme.colors.info_default};
  }
  .dark-theme & {
    color: ${theme.colors.white_pure};
  }
`;
export const PipelinePhaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  background: ${theme.colors.white_pure};
  border-radius: 14px;
  padding: 13px 14px 29px;
  margin: 0px 18px 63px;
  @media (min-width: 1440px) {
    margin: 0px 15px 42px 15px;
  }
  @media (max-width: 991px) {
    margin: 0px 8px;
    padding: 12px 16px 31px;
    border-radius: 0px;
  }
  .dropdown-item:hover {
    background: ${theme.colors.grey_input_dropdown_hover};
  }
  .dark-theme & {
    background: ${theme.colors.black110};
    .dropdown-item:hover {
      background: ${theme.colors.black130};
    }
  }
`;
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
  .dropdown-menu.show {
    top: -5px !important;
    left: -114px !important;
  }
  .dark-theme & svg path {
    fill: ${theme.colors.settings_icon};
  }
  .phase-dropdown {
    border: none;
    &::after {
      display: none;
    }
  }
`;
export const IconContainer = styled.div`
  margin: 0px 11px;
  @media (min-width: 1440px) {
    margin: 0px 15px;
  }
`;
export const PhaseContainerList = styled.div`
  display: flex;
  @media (min-width: 1440px) {
    justify-content: center;
  }
  @media (max-width: 991px) {
    display: none;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 5px;
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 0px;
  }
  .dark-theme & label {
    color: ${theme.colors.black50};
    &:first-child {
      @media (max-width: 991px) {
        color: ${theme.colors.black20};
      }
    }
  }
`;
