import { Formik } from "formik";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { Grid } from "antd";

import { Typography } from "src/components/Basic";
import Input from "../Components/Input";
import { resetValidationSchema } from "../Components/Utils";

export const Reset = () => {
  const screen = Grid.useBreakpoint();

  return (
    <Container>
      <div className="d-flex justify-content-center">
        <Typography
          content="Reset Password"
          styleName={screen.lg ? "authTitle" : "authTitleMobile"}
          color={screen.lg ? "black_pure" : "primary_default"}
        />
      </div>
      <Formik
        initialValues={{ new_password: "", confirm_password: "" }}
        onSubmit={(values) => console.log("SUBMITTED VALUES", values)}
        validationSchema={resetValidationSchema}
      >
        {(formik) => {
          return (
            <InputFieldWrapper>
              <Input
                fieldName="new_password"
                placeholder="New Password"
                isRequired={true}
                InputType="password"
                errorMessage={formik.errors.new_password}
              />
              <Input
                fieldName="confirm_password"
                placeholder="Confirm New Password"
                isRequired={true}
                InputType="password"
                errorMessage={formik.errors.confirm_password}
              />

              <ButtonContainer
                disabled={!formik.isValid}
                type="submit"
                onClick={() => {
                  formik.submitForm();
                }}
              >
                <Typography
                  content="Reset"
                  styleName="tableButton"
                  color="white_pure"
                />
              </ButtonContainer>
            </InputFieldWrapper>
          );
        }}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 100px;
  @media (min-width: 992px) {
    padding: 0;
  }
`;
const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 328px;
  margin: 36px auto 48px;

  @media (min-width: 992px) {
    gap: 28px;
    margin: 68px auto 0px;
    min-width: 348px;
  }
`;
const ButtonContainer = styled(Button)`
  @media (min-width: 992px) {
    margin-top: 16px;
  }
`;
