import { default as Button } from "./Button";
import { CreateJob } from "./CreateJob";
import { FormExample } from "./FormExample";
import { FormInputs } from "./FormInputs";
import { default as Tables } from "./Tables";
import { default as Pipeline } from "./Pipeline";
import {default as Dashboard } from "./Dashboard";

export const Pages = {
  Button,
  CreateJob,
  FormExample,
  FormInputs,
  Tables,
  Pipeline,
  Dashboard,
};
