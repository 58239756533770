import clsx from "clsx";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { ILinkButton } from "./LinkButton";
import { colorsObject } from "../../Theme";

const LinkButton: React.FC<ILinkButton.IProps> = ({
  label,
  handleClick,
  isDisable,
}) => {
  return (
    <LinkButtonContainer
      variant=""
      className={clsx("btn-link", isDisable && "disabled")}
      onClick={handleClick}
      isDisable={isDisable}
    >
      {label}
    </LinkButtonContainer>
  );
};
const LinkButtonContainer = styled(Button)<{
  isDisable: boolean;
}>`
  color: ${({ isDisable }) => (isDisable ? colorsObject.blackColors.black50 : colorsObject.blackColors.black70)} !important;
  &:hover {
    color: ${colorsObject.blackColors.black80} !important;
  }
`;

export { LinkButton };
