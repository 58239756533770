import {
  faCircleUser,
  faCommentDots,
  faEnvelope,
  faFaceSmile,
  faSun,
} from "@fortawesome/free-regular-svg-icons";

export const userSettings = [
  { icon: faCircleUser, url: "/pages/profile", title: "Profile" },
  { icon: faFaceSmile, url: "/pages/mail/chat", title: "Chat" },
  { icon: faEnvelope, url: "/pages/mail/readmail", title: "Inbox" },
  { icon: faCommentDots, url: "/pages/mail/mail", title: "Messages" },
  { icon: faSun, url: "/pages/mail/mailsettings", title: "Settings" },
];
