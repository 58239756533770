import styled from "styled-components";

import { LayoutProps } from "./Layout";
import Header from "./Header";
import Sidebar from "./Sidebar/Sidebar";
const Layout = ({ children }: LayoutProps.Children) => {
  return (
    <LayoutContainer>
      <Header />
      <Sidebar />
      {children}
    </LayoutContainer>
  );
};

export default Layout;

const LayoutContainer = styled.div`
  .main-header {
    margin-bottom: 0;
  }
`;
