import React, { DragEvent, useEffect } from "react";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";

import { theme } from "src/components/Theme";
import { Typography } from "src/components/Basic";
import {
  HappyRatingIcon,
  LocationIcon,
  MailIcon,
  NormalRatingIcon,
  PhoneIcon,
  ResizeArrow,
  SadRatingIcon,
} from "src/assets/custom_Icons";
import {
  Arrow,
  CandidateProfileContainer,
  Container,
  Detail,
  DetailContainer,
  ImageContainer,
  RatingContainer,
  Wrapper,
  ImgContainer,
} from "./CandidateProfileStyle";
import { actions, useAppDispatch, useAppSelector } from "src/Redux";
import { API_INSTANCES } from "src/API_INSTANCES";
import { PipelineKey, pipelinePhaseData } from "../Utils";
import { useParams } from "react-router-dom";
const CandidateProfile = () => {
  const { selectedCandidate } = useAppSelector((state) => state.candidate);
  const dispatch = useAppDispatch();
  const { job_id } = useParams();

  const [rating, setRating] = React.useState<string>(
    selectedCandidate.candidate_rating
  );
  const dragStart = (e: DragEvent, id: string) => {
    const target = document.getElementById("drag") as HTMLElement;
    target.style.width = "80px";
    target.style.height = "80px";
    return e.dataTransfer.setData("text", id + "_true");
  };

  const dragEnd = () => {
    const target = document.getElementById("drag") as HTMLElement;
    target.style.width = "25px";
    target.style.height = "25px";
  };
  useEffect(() => {
    setRating(selectedCandidate.candidate_rating);
  }, [selectedCandidate.candidate_rating]);

  return (
    <CandidateProfileContainer>
      <Container>
        <ImageContainer
          className="main-img-user"
          onDragStartCapture={(e) => e.preventDefault()}
        >
          <img
            alt="avatar"
            className="square"
            src={`data:image/png;base64,${selectedCandidate.profile_picture}`}
          />
          <Arrow>
            <ResizeArrow />
          </Arrow>
        </ImageContainer>
        <ImgContainer
          onDragStartCapture={(e) =>
            dragStart(e, String(selectedCandidate.application_id))
          }
          onDragEndCapture={dragEnd}
          id="drag"
        >
          <img
            alt="avatar"
            className="square"
            src={`data:image/png;base64,${selectedCandidate.profile_picture}`}
          />
        </ImgContainer>
        <div className="d-flex flex-column candidate-name-job">
          <Typography
            content={selectedCandidate.name}
            styleName="heading4"
            color="black80"
          />
          <Typography
            content={selectedCandidate.current_job_title}
            styleName="inputRegular12pt"
            color="black70"
          />
          <DetailContainer>
            <Detail>
              <PhoneIcon />
              <Typography
                content={selectedCandidate.phone}
                styleName="inputRegular12pt"
                color="black70"
              />
            </Detail>
            <Detail>
              <MailIcon />
              <Typography
                content={selectedCandidate.email}
                styleName="inputRegular12pt"
                color="black70"
              />
            </Detail>
            <Detail>
              <LocationIcon />
              <Typography
                content={selectedCandidate.location}
                styleName="inputRegular12pt"
                color="black70"
              />
            </Detail>
          </DetailContainer>
          <RatingContainer className="emoji-icons">
            <Typography
              content="RATING:"
              styleName="pipelineDefaultNav"
              color="black100"
            />
            <div className="d-flex">
              <div
                className={clsx(rating === "happy" && "emoji-icon-active")}
                onClick={() => setRating("happy")}
              >
                <HappyRatingIcon
                  color={
                    rating === "happy"
                      ? theme.colors.success_default
                      : theme.colors.black30
                  }
                />
              </div>
              <div
                className={clsx(rating === "normal" && "emoji-icon-active")}
                onClick={() => setRating("normal")}
              >
                <NormalRatingIcon
                  color={
                    rating === "normal"
                      ? theme.colors.warning_default
                      : theme.colors.black30
                  }
                />
              </div>
              <div
                className={clsx(rating === "sad" && "emoji-icon-active")}
                onClick={() => setRating("sad")}
              >
                <SadRatingIcon
                  color={
                    rating === "sad"
                      ? theme.colors.danger_default
                      : theme.colors.black30
                  }
                />
              </div>
            </div>
          </RatingContainer>
        </div>
      </Container>
      <Wrapper>
        <Dropdown
          onSelect={(value) => dispatch(actions.candidate.setPhase(value))}
        >
          <Dropdown.Toggle
            variant=""
            aria-expanded="false"
            aria-haspopup="true"
            className="btn btn-primary Onboarding-dropdown"
            data-bs-toggle="dropdown"
            type="button"
            id="phase-dropdown"
          >
            {PipelineKey[selectedCandidate.pipeline_status]}
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ margin: "0px" }}>
            {pipelinePhaseData.map((option, index) => (
              <Dropdown.Item
                eventKey={option.label}
                key={index}
                onClick={() => {
                  const body = {
                    candidate_id: selectedCandidate.candidate_id,
                    status_id: String(option.key),
                  };
                  API_INSTANCES.PIPELINE.updatePipelineOverview(
                    body,
                    job_id as string
                  )
                    .then((res) => {
                      dispatch(actions.pipelinePhase.incrementPhase(res.data));
                      dispatch(actions.candidate.setPhase(option.key));
                    })
                    .catch((err) => console.log(err));
                }}
              >
                {option.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Typography
          content={selectedCandidate.application_date}
          styleName="text_10pt"
          color="primary_default"
        />
      </Wrapper>
      <div className="candidate-detail">
        <Detail>
          <PhoneIcon />
          <Typography
            content="+94 12345 6789"
            styleName="inputRegular12pt"
            color="black70"
          />
        </Detail>
        <Detail>
          <MailIcon />
          <Typography
            content="spruko.space@gmail.com"
            styleName="inputRegular12pt"
            color="black70"
          />
        </Detail>
        <Detail>
          <LocationIcon />
          <Typography
            content="New Jersey"
            styleName="inputRegular12pt"
            color="black70"
          />
        </Detail>
      </div>
    </CandidateProfileContainer>
  );
};

export default CandidateProfile;
