const PHONE_NUMBER = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
const CONTAINS_PHONE_NUMBER =
  /[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}/im;
const EMAIL = /^\S+@\S+\.\S+$/;
const CONTAINS_EMAIL = /\S+@\S+\.\S+/;
const EXTERNAL_LINK =
  /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w-]*)?(\?[^\s]*)?/gi;
const WEBSITE = /\S+\.\S+$/;

const PASSWORD =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
const LOWERCASE = /[a-z]/;
const UPPERCASE = /[A-Z]/;
const NUMBER = /[0-9]/;
const SYMBOL = /[#?!@$%^&*-]/;

export const REGEX = {
  PHONE_NUMBER,
  CONTAINS_PHONE_NUMBER,
  EMAIL,
  CONTAINS_EMAIL,
  EXTERNAL_LINK,
  WEBSITE,
  PASSWORD,
  LOWERCASE,
  UPPERCASE,
  NUMBER,
  SYMBOL,
};
