const OpenEnvelope = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.62171 8.66633H8.6202C8.25969 8.66636 7.90544 8.57227 7.59256 8.39341L7.5919 8.39303L0.970844 4.62144L1.04699 4.48903L7.67163 8.26266L7.67227 8.26302C7.96214 8.42759 8.28978 8.51409 8.62312 8.51409C8.95646 8.51409 9.2841 8.42759 9.57397 8.26303L9.57461 8.26266L16.1992 4.48903L16.2754 4.62144L9.65434 8.39303L9.65342 8.39356C9.33943 8.57331 8.98362 8.66739 8.62171 8.66633ZM8.62025 9.16633C8.17269 9.16637 7.73289 9.04957 7.34441 8.82749L8.62025 9.16633Z"
        fill="#4A4A69"
        stroke="#4A4A69"
      />
      <path
        d="M0.94201 4.2141L0.649398 4.34748V4.66906V10.2513C0.649398 10.2708 0.641613 10.2897 0.627642 10.3037C0.613661 10.3176 0.594624 10.3256 0.574699 10.3256C0.554774 10.3256 0.535737 10.3176 0.521756 10.3037C0.507786 10.2897 0.5 10.2708 0.5 10.2513V4.25538L8.62049 0.549603L16.741 4.25538V13.9899C16.741 14.3903 16.5818 14.7744 16.2984 15.0576C16.015 15.3408 15.6305 15.5 15.2295 15.5H2.01145C1.61048 15.5 1.22599 15.3408 0.94257 15.0576C0.659157 14.7744 0.5 14.3903 0.5 13.9899V12.4049C0.5 12.3853 0.507784 12.3664 0.521756 12.3525C0.535742 12.3385 0.554781 12.3306 0.574699 12.3306C0.594618 12.3306 0.613656 12.3385 0.627642 12.3525C0.641614 12.3664 0.649398 12.3853 0.649398 12.4049V13.9899C0.649398 14.3511 0.792984 14.6975 1.04846 14.9528C1.30392 15.208 1.65032 15.3514 2.01145 15.3514H15.2295C15.5907 15.3514 15.9371 15.208 16.1925 14.9528C16.448 14.6975 16.5916 14.3511 16.5916 13.9899V4.66906V4.34748L16.299 4.2141L8.82788 0.808499L8.62049 0.713963L8.4131 0.808499L0.94201 4.2141Z"
        fill="#4A4A69"
        stroke="#4A4A69"
      />
    </svg>
  );
};

export default OpenEnvelope;
