import { Link } from "react-router-dom";
import { NovaBlack, NovaWhite } from "../../../assets/custom_Icons";
import { URLS } from "src/utils/urls";

const MobileLogo = () => {
  return (
    <div className="responsive-logo">
      <Link to={URLS.home} className="header-logo">
        <div
        
          className="mobile-logo logo-1"
      
        ><NovaBlack/></div>
        <div
          className="mobile-logo dark-logo-1"
        ><NovaWhite/></div>
      </Link>
    </div>
  );
};
export default MobileLogo;
