import * as yup from "yup";

import { REGEX } from "src/components/Basic/FormFields";
import { FormFieldsProps } from "../../Basic/FormFields/FormFields";

const options = [
  { label: "option1", value: "1" },
  { label: "option2", value: "2" },
  { label: "option3", value: "3" },
  { label: "option4", value: "4" },
  { label: "option5", value: "5" },
];

export const idPrefix = "create-job";

const job_title: FormFieldsProps.Input = {
  fieldName: "job_title",
  idPrefix,
  isRequired: true,
  label: "Job title",
};
const country: FormFieldsProps.CountrySelector = {
  fieldName: "country",
  idPrefix,
  isRequired: true,
  label: "Country",
  citySearchName: "city",
};
const city: FormFieldsProps.CitySearch = {
  fieldName: "city",
  countrySelectorName: "country",
  idPrefix,
  isRequired: true,
  label: "City",
};
const work_environment: FormFieldsProps.Dropdown = {
  fieldName: "work_environment",
  idPrefix,
  isRequired: true,
  label: "Workplace Type",
  options: [
    { label: "On-Site", value: "On-Site" },
    { label: "Hybrid", value: "Hybrid" },
    { label: "Remote", value: "Remote" },
  ],
  allowSearch: false,
};
const type: FormFieldsProps.Dropdown = {
  fieldName: "type",
  idPrefix,
  isRequired: true,
  label: "Employment Type",
  options: [
    { label: "Full-Time", value: "Full-Time" },
    { label: "Part-Time", value: "Part-Time" },
    { label: "Freelance", value: "Freelance" },
    { label: "Contract", value: "Contract" },
    { label: "Temporary", value: "Temporary" },
    { label: "Internship", value: "Internship" },
    { label: "Volunteer", value: "Volunteer" },
    { label: "Other", value: "Other" },
  ],
  allowSearch: false,
};
const industry: FormFieldsProps.Dropdown = {
  fieldName: "industry",
  idPrefix,
  isRequired: true,
  label: "Industry* (max. 3)",
  placeholder: "Industry",
  options,
  allowMultiple: true,
  multipleSelectionLimit: 3,
  showStarAfterLabel: false,
};
const skills: FormFieldsProps.Dropdown = {
  fieldName: "skills",
  idPrefix,
  isRequired: true,
  label: "Skills Required* (max. 10)",
  placeholder: "Skills Required",
  options,
  allowMultiple: true,
  multipleSelectionLimit: 10,
  showStarAfterLabel: false,
};
const experience_from: FormFieldsProps.Input = {
  label: "Experience From",
  showLabel: false,
  fieldName: "experience_from",
  idPrefix,
  isRequired: true,
  inputType: "number",
  placeholder: "From",
};
const experience_to: FormFieldsProps.Input = {
  label: "Experience To",
  showLabel: false,
  fieldName: "experience_to",
  idPrefix,
  isRequired: true,
  inputType: "number",
  placeholder: "To",
};
const currencyTypeFieldName = "base_currency";
const compensation_from: FormFieldsProps.CurrencySelector = {
  label: "Remuneration From",
  showLabel: false,
  fieldName: "remuneration_from",
  idPrefix,
  isRequired: true,
  placeholder: "From",
  currencyTypeFieldName,
};
const compensation_to: FormFieldsProps.CurrencySelector = {
  label: "Remuneration To",
  showLabel: false,
  fieldName: "remuneration_to",
  idPrefix,
  isRequired: true,
  placeholder: "To",
  currencyTypeFieldName,
};
const compensation_type: FormFieldsProps.Dropdown = {
  fieldName: "compensation_type",
  idPrefix,
  isRequired: true,
  options: [
    { label: "Hourly", value: "Hourly" },
    { label: "Daily", value: "Daily" },
    { label: "Weekly", value: "Weekly" },
    { label: "Monthly", value: "Monthly" },
    { label: "Annually", value: "Annually" },
  ],
  label: "Compensation Type",
};
const department: FormFieldsProps.Input = {
  fieldName: "department",
  idPrefix,
  isRequired: true,
  label: "Department",
};
const show_company_gallery: FormFieldsProps.Checkbox = {
  fieldName: "show_company_gallery",
  idPrefix,
  isRequired: false,
  label: "Include Company Gallery",
  helpText: { title: "title", info: "Lorem ipsum dolar" },
};

const add_external_apply_URL: FormFieldsProps.Checkbox = {
  fieldName: "add_external_apply_URL",
  idPrefix,
  isRequired: false,
  label: "Add External Apply URL",
  helpText: { title: "title", info: "Lorem ipsum dolar" },
};
const external_apply: FormFieldsProps.Input = {
  fieldName: "external_apply",
  idPrefix,
  isRequired: false,
  placeholder: "External URL",
  inputType: "URL",
};
const add_qualifying_question: FormFieldsProps.Switch = {
  fieldName: "add_qualifying_question",
  idPrefix,
  isRequired: false,
  label: "Add Qualifiers",
};

const validateNoPhoneEmailExternalLink: FormFieldsProps.customValidation = {
  validation: (value) => {
    const containsEmail = REGEX.CONTAINS_EMAIL.test(value);
    const containsPhone = REGEX.CONTAINS_PHONE_NUMBER.test(value);
    const containsExternalLink = REGEX.EXTERNAL_LINK.test(value);
    return containsEmail || containsPhone || containsExternalLink;
  },
  validationMessage:
    "Telephone numbers, email addresses, and external links not allowed. Please refer to our posting guidelines for more information.",
};
const job_description: FormFieldsProps.RichTextarea = {
  fieldName: "job_description",
  idPrefix,
  isRequired: false,
  label: "Description",
  customValidation: validateNoPhoneEmailExternalLink,
};

export const CreateJobFields = {
  job_title,
  country,
  city,
  work_environment,
  type,
  industry,
  skills,
  experience_from,
  experience_to,
  compensation_from,
  compensation_to,
  compensation_type,
  department,
  show_company_gallery,
  job_description,
  add_external_apply_URL,
  external_apply,
  add_qualifying_question,
} as const;

const countrySchema = yup.object().shape({
  id: yup.string().required("Country ID is required"),
  name: yup.string().required("Country name is required"),
  flag: yup.string().required("Country flag is required"),
  alpha2: yup.string().required("Country alpha2 code is required"),
  alpha3: yup.string().required("Country alpha3 code is required"),
  ioc: yup.string().required("Country IOC code is required"),
});
const citySchema = yup.object().shape({
  label: yup.string().required("City label is required"),
  value: yup.string().required("City value is required"),
});

export const createJobValidationSchema = yup.object().shape({
  job_title: yup.string().required("Job title is required"),
  country: countrySchema,
  city: citySchema,
  work_environment: yup.string().required("Workplace Type is required"),
  type: yup.string().required("Employment Type is required"),
  industry: yup.array().min(1, "At least one industry must be selected"),
  skills: yup.array().min(1, "At least one skill must be selected"),
  experience_from: yup
    .number()
    .required("Experience from is required")
    .min(0, "Experience from must be a positive number"),
  experience_to: yup
    .number()
    .required("Experience to is required")
    .min(
      yup.ref("experience_from"),
      "Experience to must be greater than or equal to Experience from"
    ),
  remuneration_from: yup
    .number()
    .required("Remuneration from is required")
    .min(0, "Remuneration from must be a positive number"),
  remuneration_to: yup
    .number()
    .required("Remuneration to is required")
    .min(
      yup.ref("remuneration_from"),
      "Remuneration to must be greater than or equal to Remuneration from"
    ),
  department: yup.string().required("Department is required"),
});
