import { Link } from "react-router-dom";
import styled from "styled-components";
import { NovaBlack, NovaWhite } from "../../../assets/custom_Icons";
import { URLS } from "src/utils/urls";

const DesktopLogo = () => {
  return (
    <LogoContainer className="main-sidebar-header active">
      <Link className="header-logo active" to={URLS.home}>
        <div
          className="main-logo  desktop-logo"
        ><NovaBlack/></div>
      
        <div
          className="main-logo  desktop-dark"
        ><NovaWhite/></div>
      </Link>
    </LogoContainer>
  );
};
export default DesktopLogo;
export const LogoContainer = styled.div`
  height: 64px;
  padding: 8px 20px;
  width: 240px;
  background: #fff;
  border-bottom: 1px solid #ededf5;
  border-right: 0px !important;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  -webkit-transition: left 320ms ease, width 320ms ease;
  -o-transition: left 320ms ease, width 320ms ease;
  transition: left 320ms ease, width 320ms ease;
  outline: none;
  position: unset;
`;
