import { theme } from "src/components/Theme";
import styled from "styled-components";

export const MainContainer = styled.div`
  max-width: 993px;
  margin: auto;
  @media (min-width: 1440px) {
    max-width: 1440px;
  }
`;
export const PipelineContainer = styled.div`
  margin: 39px 0px 20px 28px;
  ${theme.text.pipelineText.lg}
  .back-button {
    cursor: pointer;
    display: none;
    label {
      cursor: pointer;
    }
  }
  label:first-child {
    @media (max-width: 991px) {
      color: ${theme.colors.primary_default};
    }
  }
  @media (max-width: 991px) {
    ${theme.text.pipelineText.sm}
    margin: 7px 18px 4px 20px;
    display: flex;
    justify-content: space-between;
    .back-button {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  @media (min-width: 1440px) {
    margin: 39px 0px 20px 15px;
  }
  .dark-theme & {
    .back-button svg path {
      fill: ${theme.colors.white10};
    }
    label {
      &:first-child {
        color: ${theme.colors.primary_default};
        @media (min-width: 992px) {
          color: ${theme.colors.white10};
        }
      }
      &:last-child {
        color: ${theme.colors.white10};
      }
    }
  }
`;
export const Container = styled.div`
  margin: 0px 12px 0px 22px;
  display: flex;
  gap: 10px;
  @media (min-width: 1440px) {
    margin: 0px 15px;
  }
  @media (max-width: 991px) {
    margin: 0px 8px;
  }
`;
export const CandidateContainer = styled.div<{ isShown: boolean }>`
  background: ${theme.colors.white_pure};
  width: 100%;
  @media (max-width: 991px) {
    margin-top: 8px;
    display: ${({ isShown }) => (isShown ? "block" : "none")};
  }
  .dark-theme & {
    background: ${theme.colors.black110};
  }
`;
export const Wrapper = styled.div<{ isShown: boolean }>`
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    width: 100%;
    display: ${({ isShown }) => (isShown ? "block" : "none")};
  }
`;
