import { API_INSTANCES } from "src/API_INSTANCES";
import { JobProps } from "src/Redux/slices";
import { store, actions } from "src/Redux";
import { NavigateFunction } from "react-router-dom";
import { ROUTES } from "src/utils/ROUTES";

const dispatch = store.dispatch;

const create = (
  values: JobProps.CreateNovaJobAPI,
  navigate: NavigateFunction
) => {
  API_INSTANCES.JOBS.create(values)
    .then((response) => {
      console.log("CREATE JOB RESPONSE", response);
      navigate(ROUTES.JOBS);
    })
    .catch((error) => {
      console.log("CREATE JOB ERROR", error);
    })
    .finally(() => {
      dispatch(actions.job.setAPICallInProgress(false));
    });
};

const get = (id: string | number) => {
  API_INSTANCES.JOBS.get(id)
    .then((response) => {
      console.log("GET JOB RESPONSE", response);
    })
    .catch((error) => {
      console.log("GET JOB ERROR", error);
    });
};

export const JOBS = { create, get };
