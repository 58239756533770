import styled from "styled-components";

import { FIELDS, FIELD_COLORS } from "../../FormFieldsStyles/common";

export const DatepickerContainer = styled.div<{
  disabled: boolean;
  Error: boolean;
}>`
  ${({ Error, disabled }) => {
    if (disabled) return FIELDS.DISABLED_FORM_CONTROL;
    return Error ? FIELDS.ERROR_FORM_CONTROL : FIELDS.FORM_CONTROL;
  }};
  .input-field-icon {
    border-color: ${FIELD_COLORS.FIELD_BORDER_COLOR};
  }
  .form-control {
    border-radius: 0px 5px 5px 0;
  }
  .react-datepicker-wrapper {
    width: calc(100% - 46px) !important;
  }
  .react-datepicker {
    border-color: ${({ theme }) => theme.colors.bigSquare_and_radio};
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.primary_default};
  }
  .react-datepicker__current-month {
    color: white;
  }
  .react-datepicker__day-name {
    color: white;
    ${({ theme }) => theme.text.date_picker_day};
  }
  .react-datepicker__navigation-icon::before {
    border-color: white;
  }
  .react-datepicker__day {
    ${({ theme }) => theme.text.text_11pt};
    color: ${({ theme }) => theme.colors.black90};
    height: 27px;
    width: 27px;
    padding-top: 4px;
  }
  .react-datepicker__day--selected {
    background: ${({ theme }) => theme.colors.primary_default};
  }

  .date-picker-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 12px;
  }
  .date-picker-header-button {
    background-color: transparent;
    border: 0px;
  }
  .date-picker-icon {
    color: white;
  }
  .date-picker-header-title {
    ${({ theme }) => theme.text.date_picker_title};
  }
`;
