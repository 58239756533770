import styled from "styled-components";

import { FIELDS, FIELD_COLORS } from "../../FormFieldsStyles/common";

const Container = styled.div<{
  disabled: boolean;
  Error: boolean;
}>`
  display: flex;
  flex-direction: row;
  ${({ Error }) => (Error ? FIELDS.ERROR_FORM_CONTROL : FIELDS.FORM_CONTROL)};
`;
const InputGroupWrapper = styled.div`
  .formselect__menu {
    margin-left: -10px;
    min-width: 300px;
  }
  .formselect__control {
    min-height: 0;
    height: 28px;
    width: 28px;
    margin-top: -1px;
    color: ${FIELD_COLORS.FILLLED_TEXT_COLOR};
  }
  .formselect__value-container {
    padding: 2px 6px;
  }
  .formselect__indicators {
    display: none;
  }
  .formselect__input-container {
    padding: 0;
    margin: 0;
  }
  ${FIELDS.FORMSELECT_CONTROL};
  .formselect__single-value {
    text-overflow: unset;
  }
`;
const InputContainer = styled.div`
  width: calc(100% - 30px);

  .currency-input-field {
    border: 0 !important;
    min-height: 0;
    height: 28px;
  }
  .currency-input-field:hover {
    border: 0 !important;
    box-shadow: none !important;
  }
  .currency-input-field:active {
    border: 0 !important;
    box-shadow: none !important;
  }
  .currency-input-field:focus {
    border: 0 !important;
    box-shadow: none !important;
  }
`;

export const CurrencySelectorStyles = {
  Container,
  InputGroupWrapper,
  InputContainer,
};
