import styled, { css } from "styled-components";

import { FIELDS, FIELD_CSS } from "../../FormFieldsStyles/common";

const textareaStyle = css`
  textarea {
    margin-top: 0;
  }
  textarea::placeholder {
    ${FIELD_CSS.PLACEHOLDER};
  }
  ${FIELDS.FORM_CONTROL};
`;
const errorTextareaStyle = css`
  ${textareaStyle};
  ${FIELDS.ERROR_FORM_CONTROL};
`;
export const TextareaContainer = styled.div<{
  disabled: boolean;
  Error: boolean;
}>`
  ${({ disabled, Error }) => {
    if (disabled) return FIELDS.DISABLED_FORM_CONTROL;
    return Error ? errorTextareaStyle : textareaStyle;
  }};
  .textarea-text-count {
    width: fit-content;
    float: right;
    margin-top: 2px;
    font-weight: 300;
  }
  textarea:focus::placeholder {
    color: transparent !important;
    display: none !important;
  }
`;
