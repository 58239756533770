import styled, { css } from "styled-components";

import { FIELDS, FIELD_COLORS, FIELD_CSS } from "../../FormFieldsStyles/common";

const passwordStyle = css`
  .form-control {
    border: 1px solid ${FIELD_COLORS.FIELD_BORDER_COLOR};
    ${FIELD_CSS.TEXT};
  }
  ${FIELDS.INPUT_GROUP};
`;
const errorPasswordStyle = css`
  ${passwordStyle};
  .form-control {
    border: 1px solid ${FIELD_COLORS.ERROR_FIELD_BORDER_COLOR} !important;
  }
  ${FIELDS.ERROR_INPUT_GROUP};
`;

export const PasswordContainer = styled.div<{
  disabled: boolean;
  Error: boolean;
}>`
  ${({ Error, disabled }) => {
    if (disabled) return FIELDS.DISABLED_FORM_CONTROL;
    return Error ? errorPasswordStyle : passwordStyle;
  }};
  .form-control {
    border-right: 0 !important;
  }
  .eyeIcon {
    background-color: ${({ theme }) => theme.colors.white_pure};
    border-color: ${({ Error }) =>
      Error
        ? FIELD_COLORS.ERROR_FIELD_BORDER_COLOR
        : FIELD_COLORS.FIELD_BORDER_COLOR};
    border-left: 0;
    cursor: pointer;
  }
  input:focus::placeholder {
    color: transparent;
  }
`;
