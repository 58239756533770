const NovaWhite = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="123"
        height="48"
        viewBox="0 0 123 48"
        fill="none"
      >
        <path
          d="M22.9053 6V25L9.40527 6H3.40527V41H11.4053V22L24.9053 41H30.9053V6H22.9053ZM45.6514 41.7C53.0014 41.7 58.9014 35.95 58.9014 28.5C58.9014 21.05 53.0014 15.3 45.6514 15.3C38.3014 15.3 32.4014 21.05 32.4014 28.5C32.4014 35.95 38.3014 41.7 45.6514 41.7ZM45.6514 34.4C42.4014 34.4 39.9014 32.05 39.9014 28.5C39.9014 24.95 42.4014 22.6 45.6514 22.6C48.9014 22.6 51.4014 24.95 51.4014 28.5C51.4014 32.05 48.9014 34.4 45.6514 34.4ZM75.117 16L69.917 31.85L64.717 16H56.417L65.667 41H74.167L83.417 16H75.117ZM100.342 16V18.35C98.6924 16.45 96.2424 15.3 92.8924 15.3C86.3424 15.3 80.9424 21.05 80.9424 28.5C80.9424 35.95 86.3424 41.7 92.8924 41.7C96.2424 41.7 98.6924 40.55 100.342 38.65V41H107.842V16H100.342ZM94.3924 34.6C90.9424 34.6 88.4424 32.25 88.4424 28.5C88.4424 24.75 90.9424 22.4 94.3924 22.4C97.8424 22.4 100.342 24.75 100.342 28.5C100.342 32.25 97.8424 34.6 94.3924 34.6Z"
          fill="white"
        />
        <circle cx="116.5" cy="35.8008" r="6" fill="#1E96F2" />
      </svg>
    </>
  );
};

export default NovaWhite;
