import React from "react";
import { PageItem, PaginationItem, Paginations } from "./CandidateListStyle";
import clsx from "clsx";
import { IPipeLine } from "../PipeLine";

const Pagination: React.FC<IPipeLine.IPagination> = ({
  activePage,
  setActivePage,
  totalPage,
  page,
  setPage,
}) => {
  return (
    <Paginations>
      <PageItem
        className={clsx("indicator", activePage === 1 && "disabled")}
        onClick={() => {
          setPage(1);
          setActivePage(1);
        }}
      >{`|<`}</PageItem>
      <PageItem
        className={clsx("indicator", activePage === 1 && "disabled")}
        onClick={() => {
          if (activePage > page) {
            return setActivePage(activePage - 1);
          }
          setPage(page - 1);
          setActivePage(activePage - 1);
        }}
      >{`<`}</PageItem>
      <PaginationItem
        className={activePage === page && "active"}
        onClick={() => {
          setActivePage(page);
        }}
      >
        {page}
      </PaginationItem>
      {totalPage > 1 && (
        <PaginationItem
          className={activePage === page + 1 && "active"}
          onClick={() => {
            setActivePage(page + 1);
          }}
        >
          {page + 1}
        </PaginationItem>
      )}
      {totalPage > 2 && (
        <PaginationItem
          className={clsx(activePage === page + 2 && "active", "last-page")}
          onClick={() => {
            setActivePage(page + 2);
          }}
        >
          {page + 2}
        </PaginationItem>
      )}
      <PageItem
        className={clsx("indicator", activePage === totalPage && "disabled")}
        onClick={() => {
          if (activePage < page + 2) {
            return setActivePage(activePage + 1);
          }
          setPage(page + 1);
          setActivePage(activePage + 1);
        }}
      >
        {`>`}{" "}
      </PageItem>
      <PageItem
        className={clsx("indicator", activePage === totalPage && "disabled")}
        onClick={() => {
          if (totalPage - 2 <= 0) {
            setPage(1);
            return setActivePage(totalPage);
          }
          setPage(totalPage - 2);
          setActivePage(totalPage);
        }}
      >
        {`>|`}{" "}
      </PageItem>
    </Paginations>
  );
};

export default Pagination;
