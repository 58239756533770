import { colors, colorsObject } from "./Colors";
import { CustomColorsProps } from "./Colors/ColorsProps";

import { text, textObjects } from "./Text";
import { TextProps } from "./Text/TextProps";

import { Effects } from "./Effects";
import { EffectsProps } from "./Effects/EffectsProps";

export { colorsObject, textObjects };

export const theme = { colors, text, Effects };

export type { CustomColorsProps, TextProps, EffectsProps };
