import React from "react";

import { Typography } from "../../Basic";
import { CandidateList, CandidateProfile, PipeLinePhase } from "../../Pipeline";
import { ICandidateTabHeader } from "../../Pipeline/TabHeaderComponent/CandidateTabHeader/CandidateTabHeader";
import { LessThanIcon } from "src/assets/custom_Icons";
import {
  CandidateMobileTabHeader,
  CandidateTabHeader,
  Activity,
  Experience,
  Comments,
  Summary,
  Files,
  Qualifiers,
} from "../../Pipeline/TabHeaderComponent";
import {
  CandidateContainer,
  Container,
  MainContainer,
  PipelineContainer,
  Wrapper,
} from "./PipelineStyle";
import { Key } from "src/components/Pipeline/PipeLine";

const Pipeline = () => {
  const [step, setStep] = React.useState(0);
  const [phase, setPhase] = React.useState<Key>(0);
  const [activeTab, setActiveTab] =
    React.useState<keyof ICandidateTabHeader.TabHeader>("Summary");

  const headerdata: ICandidateTabHeader.TabHeader = {
    Experience: <Experience />,
    Summary: <Summary setActiveTab={setActiveTab} />,
    Activity: <Activity />,
    Comments: <Comments />,
    Files: <Files />,
    Qualifiers: <Qualifiers />,
  };

  return (
    <MainContainer>
      <PipelineContainer className="pipeline-heading">
        <Typography
          content="PIPELINE"
          styleName="pipelineText"
          color="black90"
        />
        <div className=" back-button" onClick={() => setStep(0)}>
          <LessThanIcon />
          <Typography content="back" styleName="backButton" color="black80" />
        </div>
      </PipelineContainer>
      <PipeLinePhase setPhase={setPhase} />
      <Container>
        <Wrapper isShown={step === 0}>
          <CandidateList
            phase={phase}
            setPhase={setPhase}
            setStep={setStep}
            setActiveTab={setActiveTab}
          />
        </Wrapper>
        <CandidateContainer isShown={step === 1}>
          <CandidateProfile />
          <CandidateTabHeader
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <CandidateMobileTabHeader
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {headerdata[activeTab]}
        </CandidateContainer>
      </Container>
    </MainContainer>
  );
};

export default Pipeline;
