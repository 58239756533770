import { AUTH } from "./AUTH";
import { JOBS } from "./JOBS";
import { COMMON } from "./COMMON";
import { PIPELINE } from "./PIPELINE";
import { JOB } from "./JOB";

export const API_INSTANCES = {
  AUTH,
  JOBS,
  COMMON,
  PIPELINE,
  JOB,
};
