import { colors } from "src/components/Theme/Colors";
import { IIconProps } from "./customIcons";

const UserIcon = ({
  width,
  height,
  color = "white_pure",
}: IIconProps.Iprops) => {
  return (
    <svg
      className="user-icon"
      width={width || "20"}
      height={height || "21"}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6666 18V16.3333C16.6666 15.4493 16.3154 14.6014 15.6903 13.9763C15.0652 13.3512 14.2173 13 13.3333 13H6.66659C5.78253 13 4.93468 13.3512 4.30956 13.9763C3.68444 14.6014 3.33325 15.4493 3.33325 16.3333V18"
        stroke={colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0001 9.66667C11.841 9.66667 13.3334 8.17428 13.3334 6.33333C13.3334 4.49238 11.841 3 10.0001 3C8.15913 3 6.66675 4.49238 6.66675 6.33333C6.66675 8.17428 8.15913 9.66667 10.0001 9.66667Z"
        stroke={colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserIcon;
