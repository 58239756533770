import { IIconProps } from "./customIcons";

const BriefCaseIconWithVerticalLine: React.FC<IIconProps.Iprops> = ({
  width,
}) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M83.3335 29.1666H16.6668C12.0645 29.1666 8.3335 32.8976 8.3335 37.5V79.1666C8.3335 83.769 12.0645 87.5 16.6668 87.5H83.3335C87.9359 87.5 91.6668 83.769 91.6668 79.1666V37.5C91.6668 32.8976 87.9359 29.1666 83.3335 29.1666Z"
        stroke="#4A4A69"
        strokeWidth={width || "3"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.6668 87.5V20.8333C66.6668 18.6232 65.7889 16.5036 64.226 14.9408C62.6632 13.378 60.5436 12.5 58.3335 12.5H41.6668C39.4567 12.5 37.3371 13.378 35.7743 14.9408C34.2115 16.5036 33.3335 18.6232 33.3335 20.8333V87.5"
        stroke="#4A4A69"
        strokeWidth={width || "3"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BriefCaseIconWithVerticalLine;
