import { useEffect, useState } from "react";
import Select from "react-select";
import { Field, FieldProps } from "formik";
import { Form, InputGroup } from "react-bootstrap";

import { FormFieldsProps } from "../../FormFields";
import { FormFieldCommon } from "../../FormFieldsCommon";
import { CurrencySelectorStyles } from "./currencySelectorStyles";
import { REGEX } from "../../REGEX";
import { CommonProps, useAppSelector } from "src/Redux";

const defaultCurrencyOptions: CommonProps.currencyOption[] = [
  { label: "EUR", value: "€" },
  { label: "ZAR", value: "R" },
  { label: "GBP", value: "£" },
  { label: "USD", value: "$" },
];
const defaultCurrencyDict: CommonProps.currency_dict = {
  EUR: "€",
  GBP: "£",
  USD: "$",
  ZAR: "R",
};

const getDropdownDefaultValue = (
  currencyOptions: CommonProps.currencyOption[],
  label: string
) =>
  currencyOptions.find(
    (option) => option.label.toLowerCase() === label.toLowerCase()
  );

const updateCurrencyValue = (currency_dict: CommonProps.currency_dict) => {
  const elements = document.getElementsByClassName("formselect__single-value");
  for (let i = 0; i < elements.length; i++) {
    const existingValue = elements[i]
      .innerHTML as FormFieldsProps.CurrencyLabels;
    if (!existingValue || !currency_dict[existingValue]) continue;
    elements[i].innerHTML = currency_dict[existingValue];
  }
};

export const CurrencySelector = (props: FormFieldsProps.CurrencySelector) => {
  const { currency_dict } = useAppSelector((store) => store.common);
  let currencyOptions = defaultCurrencyOptions;

  if (Object.keys(currency_dict).length > 0)
    currencyOptions = Object.entries(currency_dict).map(([label, value]) => ({
      label,
      value,
    })) as CommonProps.currencyOption[];

  const {
    fieldName,
    idPrefix,
    isRequired,
    disabled = false,
    errorMessage,
    label,
    placeholder = label || fieldName,
    needLabelPlaceholder = false,
    currencyType = currencyOptions[0],
    currencyTypeFieldName,
    onCurrencyChangeHandler,
    showLabel = true,
  } = props;

  useEffect(() => {
    const newCurrencyDict =
      Object.keys(currency_dict).length === 0
        ? defaultCurrencyDict
        : currency_dict;
    updateCurrencyValue(newCurrencyDict);
  }, [currencyType, currency_dict]);

  const [isDefaultSet, setIsDefaultSet] = useState(false);
  const [isFocused, setFocused] = useState(false);

  return (
    <Field name={fieldName} key={fieldName}>
      {({ meta, form, field }: FieldProps) => {
        const { onBlur, onChange } = field;
        const { touched, value = "" } = meta;
        const { setFieldValue, getFieldMeta } = form;

        const hasError = !isFocused && isRequired && touched && !value;
        const errorLabel = errorMessage || `${label || fieldName} is required`;
        const showErrorLabel = hasError && errorLabel;

        //setting default currency value in case of edit form
        const { value: currencyValue } = getFieldMeta(currencyTypeFieldName);

        let newCurrencyType =
          currencyValue ||
          (Object.keys(currencyType).length !== 0
            ? currencyType
            : currencyOptions[0]);

        if (typeof currencyValue === "string") {
          const defaultValue = getDropdownDefaultValue(
            currencyOptions,
            currencyValue
          );
          if (defaultValue) {
            newCurrencyType = defaultValue;
            setFieldValue(currencyTypeFieldName, newCurrencyType);
            updateCurrencyValue(currency_dict);
          }
        }
        if (!isDefaultSet && newCurrencyType) {
          setFieldValue(currencyTypeFieldName, newCurrencyType);
          setIsDefaultSet(true);
        }

        return (
          <div>
            {showLabel && label && (
              <FormFieldCommon.Label
                content={`${label}${isRequired ? "*" : ""}`}
              />
            )}
            {!label && needLabelPlaceholder && (
              <FormFieldCommon.LabelPlaceHolder />
            )}
            <CurrencySelectorStyles.Container
              disabled={disabled}
              Error={hasError}
            >
              <CurrencySelectorStyles.InputGroupWrapper
                id={`${idPrefix}_${fieldName}`}
                className="form-control"
              >
                <InputGroup>
                  <Select
                    id={`${idPrefix}_${currencyTypeFieldName}`}
                    name={`${currencyTypeFieldName}`}
                    onChange={(item) => {
                      setFieldValue(currencyTypeFieldName, item);
                      onCurrencyChangeHandler && onCurrencyChangeHandler(item);
                    }}
                    options={currencyOptions}
                    classNamePrefix="formselect"
                    className="currency-selector-select"
                    isSearchable={false}
                    isMulti={false}
                    isDisabled={disabled}
                    value={newCurrencyType}
                  />
                  <CurrencySelectorStyles.InputContainer>
                    <Form.Control
                      id={`${idPrefix}_${fieldName}`}
                      type="text"
                      className="currency-input-field"
                      name={fieldName}
                      placeholder={placeholder}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (!value) {
                          onChange(e);
                          return;
                        }
                        if (!REGEX.NUMBER.test(value)) return;

                        const indexOfDecimalPoint = value.indexOf(".");
                        if (indexOfDecimalPoint < value.length - 2)
                          setFieldValue(fieldName, Number.parseFloat(value));
                        else setFieldValue(fieldName, value);
                      }}
                      value={value}
                      disabled={disabled}
                      onFocus={() => setFocused(true)}
                      onBlur={(e) => {
                        onBlur(e);
                        setFocused(false);
                      }}
                    />
                  </CurrencySelectorStyles.InputContainer>
                </InputGroup>
              </CurrencySelectorStyles.InputGroupWrapper>
            </CurrencySelectorStyles.Container>
            {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
          </div>
        );
      }}
    </Field>
  );
};
