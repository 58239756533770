import React, { createContext, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";

import "./App.css";
import Layout from "./components/layout";
import { Pages } from "./components/pages";
import { Auth } from "./components/Auth";

import AuthLayout from "./components/Auth/Components/AuthLayout";
import { ROUTES } from "./utils/ROUTES";
import { theme } from "src/components/Theme";
import { Protected } from "./components";
import store from "./Redux";
import { colors } from "./components/Theme/Colors";
import Job from "./components/Job";

type context = {
  isDarkTheme: boolean;
  setIsDarkTheme: React.Dispatch<React.SetStateAction<boolean>>;
};
export const AppContext = createContext({} as context);

function App() {
  document
    .querySelector("body")
    ?.classList.add("ltr", "main-body", "app", "sidebar-mini");
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  return (
    <AppContext.Provider value={{ isDarkTheme, setIsDarkTheme }}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <PageBackground>
            <Router>
              <Routes>
                <Route path="/button" element={<Pages.Button />} />
                <Route path="/table" element={<Pages.Tables />} />
                <Route path="/form-input" element={<Pages.FormInputs />} />
                <Route
                  path="/form-Example"
                  element={
                    <Protected>
                      <Pages.FormExample />
                    </Protected>
                  }
                />
                <Route
                  path={ROUTES.CREATE_JOB}
                  element={
                    <Protected>
                      <Layout>
                        <Pages.CreateJob />
                      </Layout>
                    </Protected>
                  }
                />
                <Route
                  path={ROUTES.PIPELINE}
                  element={
                    <Protected>
                      <Layout>
                        <Pages.Pipeline />
                      </Layout>
                    </Protected>
                  }
                />
                <Route
                  path={ROUTES.JOBS}
                  element={
                    <Protected>
                      <Layout>
                        <Job />
                      </Layout>
                    </Protected>
                  }
                />

                <Route element={<AuthLayout />}>
                  <Route path={ROUTES.SIGN_IN} element={<Auth.SignIn />} />
                  <Route path={ROUTES.REGISTER} element={<Auth.Register />} />
                  <Route path={ROUTES.FORGOT} element={<Auth.Forgot />} />
                  <Route path={ROUTES.RESET} element={<Auth.Reset />} />
                  <Route path={ROUTES.OTP} element={<Auth.OTP />} />
                </Route>
                <Route
                  path={ROUTES.HOME}
                  element={
                    <Protected>
                      <Layout>
                        <Pages.Dashboard />
                      </Layout>
                    </Protected>
                  }
                />
              </Routes>
            </Router>
          </PageBackground>
        </ThemeProvider>
      </Provider>
    </AppContext.Provider>
  );
}

const PageBackground = styled.div`
  background: ${colors.white100};
  .dark-theme & {
    background: ${colors.black120};
  }
`;

export default App;
