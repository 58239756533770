import { LoginProps } from "./Login";
import { createSlice } from "@reduxjs/toolkit";

const initialState: LoginProps.State = {
  isLoggedin: false,
  accessToken: "",
  urlBeforeInitiatingLogin: "",
  formValues: {} as LoginProps.FormValues,
  token_type: "",
  errorMessageFromAPI: "",
  isAPICallInProgress: false,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoginState: (state, action: LoginProps.setLoginState) => {
      state.isLoggedin = action.payload || false;
    },
    setAccessToken: (state, action: LoginProps.setAccessToken) => {
      state.accessToken = action.payload || "";
    },
    setTokenType: (state, action: LoginProps.setTokenType) => {
      state.accessToken = action.payload || "";
    },
    setErrorMessageFromAPI: (
      state,
      action: LoginProps.setErrorMessageFromAPI
    ) => {
      state.errorMessageFromAPI = action.payload || "";
    },
    setUrlBeforeInitiatingLogin: (
      state,
      action: LoginProps.setUrlBeforeInitiatingLogin
    ) => {
      state.urlBeforeInitiatingLogin = action.payload || "";
    },
    setFormValues: (state, action: LoginProps.setFormValues) => {
      state.formValues = action.payload || initialState.formValues;
    },
    setAPICallInProgress: (state, action: LoginProps.setAPICallInProgress) => {
      state.isAPICallInProgress = action.payload;
    },
  },
});

export const loginActions = loginSlice.actions;
export const loginReducer = loginSlice.reducer;
