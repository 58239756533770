import React from "react";

const HappyRatingIcon: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_52_1233)">
        <path
          d="M10.9997 20.1666C16.0623 20.1666 20.1663 16.0626 20.1663 11C20.1663 5.93737 16.0623 1.83331 10.9997 1.83331C5.93706 1.83331 1.83301 5.93737 1.83301 11C1.83301 16.0626 5.93706 20.1666 10.9997 20.1666Z"
          fill="white"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.33301 12.8333C7.33301 12.8333 8.70801 14.6666 10.9997 14.6666C13.2913 14.6666 14.6663 12.8333 14.6663 12.8333"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.25 8.25H8.25833"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.75 8.25H13.7583"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_52_1233">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HappyRatingIcon;
