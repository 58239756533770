import React from "react";
import { Dropdown, ProgressBar } from "react-bootstrap";
import { Typography } from "../Basic";
import { IJobs } from "./Job";
import {
  Container,
  DropDownContainer,
  DropDownMenuContainer,
  IconContainer,
  RowCellContainer,
  JobRowCellContainer,
} from "./JobStyle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

export const customRowCell: React.FC<IJobs.CustomCell> = ({
  cell,
  filterType,
  setIsModalOpen,
  navigate,
}) => {
  console.log((cell.row.original as IJobs.Job).id);
  switch (cell.column.Header) {
    case "Job":
      return (
        <JobRowCellContainer
          className="job-column"
          onClick={() =>
            navigate(`/pipeline/${(cell.row.original as IJobs.Job).id}`)
          }
        >
          <Typography
            content={cell.value}
            styleName="tableRowDefault"
            color="black85"
          />
          <Typography
            content={(cell.row.original as IJobs.Job).city}
            styleName="tableSubHeading"
            color="blue_grey"
          />
        </JobRowCellContainer>
      );
    case "UserPhoto":
      return <IconContainer src={`data:image/png;base64,${cell.value}`} />;
    case "Options":
      return (
        <Container>
          <Dropdown>
            <DropDownContainer className="btn btn-danger">
              <FontAwesomeIcon
                icon={faEllipsisV}
                size="xl"
                className="user-icon"
              />
            </DropDownContainer>
            <DropDownMenuContainer className="dropdown-menu tx-13 custom-dropdown">
              <Dropdown.Item className="dropdown-item" href="#">
                <Typography
                  content={"Edit"}
                  styleName="tableSubHeading"
                  color="black_pure"
                />
              </Dropdown.Item>
              {(filterType === "active" || filterType === "drafts") && (
                <Dropdown.Item className="dropdown-item" href="#">
                  <Typography
                    content={"Advertising"}
                    styleName="tableSubHeading"
                    color="black_pure"
                  />
                </Dropdown.Item>
              )}
              {filterType === "active" && (
                <Dropdown.Item className="dropdown-item" href="#">
                  <Typography
                    content={"View"}
                    styleName="tableSubHeading"
                    color="black_pure"
                  />
                </Dropdown.Item>
              )}
              {filterType === "drafts" && (
                <Dropdown.Item
                  className="dropdown-item"
                  href="#"
                  onClick={() => setIsModalOpen(true)}
                >
                  <Typography
                    content={"Delete"}
                    styleName="tableSubHeading"
                    color="black_pure"
                  />
                </Dropdown.Item>
              )}
              {filterType === "archived" && (
                <Dropdown.Item className="dropdown-item" href="#">
                  <Typography
                    content={"Settings"}
                    styleName="tableSubHeading"
                    color="black_pure"
                  />
                </Dropdown.Item>
              )}
            </DropDownMenuContainer>
          </Dropdown>
        </Container>
      );
    case "Campaign Status":
      return (
        <RowCellContainer className="job-column">
          <ProgressBar
            variant="success"
            role="progressbar"
            now={Number(cell.value.slice(0, cell.value.length - 1))}
          />
        </RowCellContainer>
      );

    case "User":
    case "View":
      return (
        <RowCellContainer className="job-column">
          <Typography
            content={cell.value}
            styleName="tableSubHeading"
            color="blue_grey"
          />
        </RowCellContainer>
      );
    default:
      return (
        <RowCellContainer className="job-column">
          <Typography
            content={cell.value}
            styleName="tableRowDefault"
            color="black85"
          />
        </RowCellContainer>
      );
  }
};
