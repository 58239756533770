import { FormikProps, FormikValues } from "formik";
import { FormFieldsProps } from "../FormFields";

export const getRequiredFieldNameWithNoValue = (
  requiredInputFields: Object,
  formikValues: FormikValues
) => {
  const keys = Object.keys(requiredInputFields);
  const isRequiredFieldNameWithNoValue = keys.find((key) => {
    if (Array.isArray(formikValues[key])) return formikValues[key].length === 0;

    return formikValues[key] ? false : true;
  });
  return isRequiredFieldNameWithNoValue;
};

export const setFocusOnErrorNode = (errorNodeId: string) => {
  const errorNode = document.getElementById(errorNodeId);
  if (errorNode) {
    errorNode.scrollIntoView();
    errorNode.focus();
  }
};

type formikSubmitHandlerProps = {
  formFields: FormFieldsProps.Fields;
  formik: FormikProps<any>;
  onSubmitHandler: (values: FormikValues) => void;
  idPrefix: string;
};
export const formikSubmitHandler = ({
  formFields,
  formik,
  idPrefix,
  onSubmitHandler,
}: formikSubmitHandlerProps) => {
  const requiredFields = {} as { [key: string]: boolean };
  Object.entries(formFields).forEach(([_name, value]) =>
    value.isRequired ? (requiredFields[value.fieldName] = true) : ""
  );

  formik.setTouched(requiredFields);
  formik.validateForm();
  const requiredFieldNameWithNoValue = getRequiredFieldNameWithNoValue(
    requiredFields,
    formik.values
  );
  const isFormUnTouched = Object.keys(formik.touched).length === 0;
  const isNoErrors = isFormUnTouched || Object.keys(formik.errors).length === 0;
  const isValidForm = !requiredFieldNameWithNoValue && isNoErrors;
  !isValidForm && setFocusOnErrorNode(idPrefix + requiredFieldNameWithNoValue);
  isValidForm && onSubmitHandler(formik.values);
  return isValidForm;
};
