import { IIconProps } from "./customIcons";

const QualifierIcon: React.FC<IIconProps.Iprops> = ({ width }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 19V17C17 15.9391 16.5786 14.9217 15.8284 14.1716C15.0783 13.4214 14.0609 13 13 13H5C3.93913 13 2.92172 13.4214 2.17157 14.1716C1.42143 14.9217 1 15.9391 1 17"
        stroke="#4A4A69"
        strokeLinecap="round"
        strokeWidth={width || "3"}
        strokeLinejoin="round"
      ></path>
      <path
        d="M9 9C11.2091 9 13 7.20914 13 5C13 2.79086 11.2091 1 9 1C6.79086 1 5 2.79086 5 5C5 7.20914 6.79086 9 9 9Z"
        stroke="#4A4A69"
        strokeLinecap="round"
        strokeWidth={width || "3"}
        strokeLinejoin="round"
      />
      <circle
        cx="16.5"
        cy="16.5"
        r="7"
        fill="#F9F9FB"
        stroke="#4A4A69"
        strokeWidth={width || "3"}
      />
      <path
        d="M21 14L14.8125 20L12 17.2727"
        stroke="#4A4A69"
        strokeLinecap="round"
        strokeWidth={width || "3"}
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default QualifierIcon;
