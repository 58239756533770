import { IIconProps } from "./customIcons";

const MessageIcon: React.FC<IIconProps.Iprops> = ({ width }) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M85.0592 68.3926C86.622 66.8298 87.5 64.7101 87.5 62.5V20.8333C87.5 18.6232 86.622 16.5036 85.0592 14.9408C83.4964 13.378 81.3768 12.5 79.1667 12.5H20.8333C18.6232 12.5 16.5036 13.378 14.9408 14.9408C13.378 16.5036 12.5 18.6232 12.5 20.8333V87.5L29.1667 70.8333H79.1667C81.3768 70.8333 83.4964 69.9554 85.0592 68.3926Z"
        stroke="#4A4A69"
        strokeWidth={width || "3"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50 44.2917C51.5188 44.2917 52.75 43.0418 52.75 41.5C52.75 39.9582 51.5188 38.7084 50 38.7084C48.4812 38.7084 47.25 39.9582 47.25 41.5C47.25 43.0418 48.4812 44.2917 50 44.2917Z"
        fill="#4A4A69"
        stroke="#4A4A69"
        strokeWidth={width || "3"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.25 44.2917C70.7688 44.2917 72 43.0418 72 41.5C72 39.9582 70.7688 38.7084 69.25 38.7084C67.7312 38.7084 66.5 39.9582 66.5 41.5C66.5 43.0418 67.7312 44.2917 69.25 44.2917Z"
        fill="#4A4A69"
        stroke="#4A4A69"
        strokeWidth={width || "3"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.75 44.2917C32.2688 44.2917 33.5 43.0418 33.5 41.5C33.5 39.9582 32.2688 38.7084 30.75 38.7084C29.2312 38.7084 28 39.9582 28 41.5C28 43.0418 29.2312 44.2917 30.75 44.2917Z"
        fill="#4A4A69"
        stroke="#4A4A69"
        strokeWidth={width || "3"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MessageIcon;
