export const StartComma = () => (
  <svg
    width="26"
    height="20"
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.83938 1.88C5.47938 1.08 6.11938 0.626663 6.75938 0.519998C7.39938 0.413332 7.95938 0.546666 8.43938 0.919997C8.91938 1.24 9.18604 1.74666 9.23938 2.44C9.34604 3.08 9.07938 3.8 8.43938 4.6C7.47938 5.82666 6.81271 7.05333 6.43938 8.28C6.06604 9.50666 5.87938 10.6267 5.87938 11.64L5.63938 9.56C7.23938 9.56 8.51938 10.04 9.47938 11C10.4927 11.9067 10.9994 13.1333 10.9994 14.68C10.9994 16.12 10.5194 17.32 9.55938 18.28C8.65271 19.24 7.45271 19.72 5.95938 19.72C4.35938 19.72 3.07938 19.16 2.11938 18.04C1.21271 16.92 0.759375 15.3733 0.759375 13.4C0.759375 12.2267 0.866042 11 1.07938 9.72C1.34604 8.44 1.77271 7.13333 2.35938 5.8C2.99938 4.46666 3.82604 3.16 4.83938 1.88ZM19.1594 1.88C19.7994 1.08 20.4394 0.626663 21.0794 0.519998C21.7194 0.413332 22.2794 0.546666 22.7594 0.919997C23.2394 1.24 23.506 1.74666 23.5594 2.44C23.666 3.08 23.3994 3.8 22.7594 4.6C21.7994 5.82666 21.1327 7.05333 20.7594 8.28C20.386 9.50666 20.1994 10.6267 20.1994 11.64L19.9594 9.56C21.5594 9.56 22.8394 10.04 23.7994 11C24.8127 11.9067 25.3194 13.1333 25.3194 14.68C25.3194 16.12 24.8394 17.32 23.8794 18.28C22.9727 19.24 21.7727 19.72 20.2794 19.72C18.6794 19.72 17.3994 19.16 16.4394 18.04C15.5327 16.92 15.0794 15.3733 15.0794 13.4C15.0794 12.2267 15.186 11 15.3994 9.72C15.666 8.44 16.0927 7.13333 16.6794 5.8C17.3194 4.46666 18.146 3.16 19.1594 1.88Z"
      fill="white"
    />
  </svg>
);

