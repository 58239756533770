import { Dropdown, Navbar } from "react-bootstrap";
import { BellIcon } from "src/assets/custom_Icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faFolderOpen,
} from "@fortawesome/free-regular-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { userSettings } from "src/utils/userSettings";
import { API_CALLS } from "src/API_CALLS";
import { DarkModeButton } from "src/components/Basic";

const RightHeaderSection = () => {
  const navigate = useNavigate();
  return (
    <div className="main-header-right">
      <div className="mb-0 navbar-expand-lg navbar-nav-right responsive-navbar navbar-dark p-0">
        <Navbar.Collapse className="collapse" id="navbarSupportedContent-4">
          <ul className="nav nav-item header-icons navbar-nav-right">
            <DarkModeButton />

            <Dropdown className="nav-item main-header-notification d-flex">
              <Dropdown.Toggle className="new nav-link" variant="">
                <BellIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu className="slid1">
                <div className="menu-header-content text-start border-bottom">
                  <div className="d-flex">
                    <h6 className="dropdown-title mb-1 tx-15 font-weight-semibold">
                      Notifications
                    </h6>
                    <span className="badge badge-pill badge-warning ms-auto my-auto float-end">
                      Mark All Read
                    </span>
                  </div>
                  <p className="dropdown-title-text subtext mb-0 op-6 pb-0 tx-12 ">
                    You have 4 unread Notifications
                  </p>
                </div>
                <div style={{ height: 280, overflow: "auto" }}>
                  <div className="main-notification-list Notification-scroll">
                    <Dropdown.Item
                      className="d-flex p-3 border-bottom"
                      href={`${process.env.PUBLIC_URL}/pages/mail/mail`}
                    >
                      <div className="notifyimg bg-pink">
                        <FontAwesomeIcon icon={faFolderOpen} size="xl" />
                      </div>
                      <div className="ms-3">
                        <h5 className="notification-label mb-1">
                          New files available
                        </h5>
                        <div className="notification-subtext">10 hour ago</div>
                      </div>
                      <div className="ms-auto">
                        {/* <i className="las la-angle-right text-end text-muted"></i> */}
                        <FontAwesomeIcon icon={faAngleRight} size="xl" />
                      </div>
                    </Dropdown.Item>
                  </div>
                </div>
                <div className="dropdown-footer">
                  <Link
                    className="btn btn-primary btn-sm btn-block"
                    to={`${process.env.PUBLIC_URL}/pages/mail/mail`}
                  >
                    VIEW ALL
                  </Link>
                </div>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="main-profile-menu nav nav-item nav-link ps-lg-2">
              <Dropdown.Toggle
                className="new nav-link profile-user d-flex"
                variant=""
              >
                <img
                  alt=""
                  // src={require("../../../assets/img/faces/2.jpg")}
                  className=""
                />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="menu-header-content p-3 border-bottom">
                  <div className="d-flex wd-100p">
                    <div className="main-img-user">
                      <img
                        alt=""
                        // src={require("../../../assets/img/faces/2.jpg")}
                        className=""
                      />
                    </div>
                    <div className="ms-3 my-auto">
                      <h6 className="tx-15 font-weight-semibold mb-0">
                        Teri Dactyl
                      </h6>
                      <span className="dropdown-title-text subtext op-6  tx-12">
                        Premium Member
                      </span>
                    </div>
                  </div>
                </div>

                {userSettings.map(({ icon, url, title }, index) => (
                  <Dropdown.Item
                    key={`${title}_${index}`}
                    className="dropdown-item"
                    href={`${process.env.PUBLIC_URL}${url}`}
                  >
                    <FontAwesomeIcon icon={icon} size="xl" />
                    {title}
                  </Dropdown.Item>
                ))}
                <Dropdown.Item
                  className="dropdown-item"
                  onClick={() => {
                    API_CALLS.AUTH.logout(navigate);
                  }}
                >
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} size="xl" />
                  Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
        </Navbar.Collapse>
      </div>
    </div>
  );
};
export default RightHeaderSection;
