import { candidateActions, candidateReducer } from "./Candidate";
import { jobActions, jobReducer } from "./Job";
import { dashboardActions, dashboardReducer } from "./Dashboard";
import { loginActions, loginReducer } from "./Login";
import { OTPActions, OTPReducer } from "./OTP";
import { pipelinePhaseActions, pipelinePhaseReducer } from "./PipelinePhase";
import { registerActions, registerReducer } from "./Register";
import { activeJobActions, activeJobReducer } from "./ActiveJobs";
import { commonActions, commonReducer } from "./Common";

export type { RegisterProps } from "./Register/Register";
export type { LoginProps } from "./Login/Login";
export type { OTPProps } from "./OTP/OTP";
export type { JobProps } from "./Job/Job";
export type { CommonProps } from "./Common/Common";

export const actions = {
  login: loginActions,
  OTP: OTPActions,
  register: registerActions,
  pipelinePhase: pipelinePhaseActions,
  candidate: candidateActions,
  job: jobActions,
  common: commonActions,
  activeJob: activeJobActions,
  dashboard: dashboardActions,
};
export const reducer = {
  login: loginReducer,
  OTP: OTPReducer,
  register: registerReducer,
  pipelinePhase: pipelinePhaseReducer,
  candidate: candidateReducer,
  job: jobReducer,
  common: commonReducer,
  activeJob: activeJobReducer,
  dashboard: dashboardReducer,
};
