import { theme } from "src/components/Theme";
import styled from "styled-components";

export const SummaryContainer = styled.div`
  margin: 20px 157px 49px 40px;
  @media (max-width: 991px) {
    margin: 16px;
  }
  @media (min-width: 1440px) {
    margin: 20px 48px 20px 38px;
  }
`;
export const DetailContainer = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  background: ${theme.colors.light_default};
  border-top: 2px solid ${theme.colors.primary_default};
  padding: 16px 21px 39px 26px;
  &::before {
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    top: 16px;
    left: -10px;
    transform: rotate(45deg);
    background: ${theme.colors.light_default};
  }
  @media (min-width: 1440px) {
    width: 351px;
    padding: 16px 31px 46px 24px;
  }
  .dark-theme & {
    background: ${theme.colors.black190};
    &::before {
      background: ${theme.colors.black190};
    }
    label {
      color: ${theme.colors.black10};
    }
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-basis: 50%;
`;
export const RecentPositionDetailContainer = styled.div`
  display: flex;
  gap: 51px;
  @media (max-width: 991px) {
    background: ${theme.colors.light_default};
    border-radius: 5px;
    padding: 12px;
  }
  .dark-theme & {
    @media (max-width: 991px) {
      background: ${theme.colors.black120};
  }
`;
export const RecentPositionDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  & > div > label:first-child {
    @media (max-width: 991px) {
      font-weight: 700;
    }
  }
  & > label:first-child {
    @media (max-width: 991px) {
      display: none;
    }
  }
  .dark-theme & > label:last-child {
    color: ${theme.colors.black50};
  }
`;
export const Details = styled.div`
  color: ${theme.colors.black80};
  ${theme.text.summaryTextDesc};
  letter-spacing: 0.01em;
  margin: 11px 0 20px 0px;
  .dark-theme & {
    color: ${theme.colors.black50};
  }
`;
export const SkillContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${theme.colors.primary_default};
  border-radius: 20px;
  padding: 6px 14px;
  width: fit-content;
  color: ${theme.colors.primary_default};
  ${theme.text.tagText};
  @media (max-width: 991px) {
    border-color: ${theme.colors.black70};
    color: ${theme.colors.black70};
  }
  .dark-theme & {
    color: ${theme.colors.black50};
    background: ${theme.colors.black130};
    border-color: ${theme.colors.black130};
  }
`;

export const Value = styled.label`
  color: ${theme.colors.primary_default};
  ${theme.text.summaryTextDesc}
  &.summary-recent-position {
    @media (max-width: 991px) {
      color: ${theme.colors.black80};
    }
  }
  .dark-theme &.summary-recent-position {
    @media (max-width: 991px) {
      color: ${theme.colors.black10};
    }
  }
`;
export const Heading = styled.label`
  color: ${theme.colors.black80};
  ${theme.text.candidateNameEmailOpen}
  .dark-theme & {
    color: ${theme.colors.black10};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  button,
  button:hover,
  button:active,
  button:focus {
    @media (max-width: 991px) {
      border: none;
      background: transparent !important;
      color: ${theme.colors.black80} !important;
      text-decoration: underline !important;
      ${theme.text.summaryDateText}
    }
  }
  .dark-theme & button {
    @media (max-width: 991px) {
      color: ${theme.colors.white10} !important;
    }
  }
`;

export const MainContainer = styled.div`
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  gap: 49px;
  @media (min-width: 1440px) {
    gap: 67px;
    flex-direction: row;
  }
  @media (max-width: 991px) {
    margin-top: 20px;
  }
  & > div {
    @media (max-width: 991px) {
      width: 100%;
    }
  }
`;

export const Skills = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  width: 100%;
`;
export const Wrapper = styled.div`
  display: flex;
  margin-top: 40px;
  @media (max-width: 991px) {
    margin-top: 28px;
    flex-direction: column;
    gap: 28px;
  } ;
`;

export const HeadingContainer = styled.div`
  margin-top: 24px;
  .dark-theme &.no-data-msg label {
    color: ${theme.colors.black10};
  }
`;
export const IconContainer = styled.div`
  margin: auto;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 84px;
    height: 84px;
    @media (max-width: 991px) {
      width: 35px;
      height: 35px;
    }
  }
  @media (max-width: 991px) {
    margin: 55px auto 80px;
  }
  .dark-theme & svg path {
    stroke: ${theme.colors.light_default};
  }
`;
