import styled from "styled-components";
import { colors } from "src/components/Theme/Colors";

export const InputFieldWrapper = styled.div`
  max-width: 328px;
  margin: 36px auto;
  @media (min-width: 992px) {
    margin: 68px auto 8px;
    min-width: 348px;
  }
`;
export const Circle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  cursor: pointer;
`;
export const Container = styled.div<{ step: number }>`
  display: flex;
  justify-content: center;
  margin: 20px auto 32px;
  gap: 8px;
  div:first-child {
    background: ${({ step }) =>
      step === 1 ? colors.black100 : colors.black30};
  }
  div:last-child {
    background: ${({ step }) =>
      step === 1 ? colors.black30 : colors.black100};
  }
  @media (min-width: 992px) {
    margin: 15px auto 48px;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  label {
    display: flex;
    justify-content: center;
  }
  a label {
    cursor: pointer;
  }
  div {
    display: flex;
    justify-content: center;
  }
`;
export const ContentWrapper = styled.div`
  display: flex;
  gap: 4px;
  label:last-child {
    text-decoration: underline;
    cursor: pointer;
  }
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
